import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, TextField, Divider } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { LocalizationProvider, DateTimePicker, DatePicker, StaticDatePicker, PickersDay, CalendarPickerSkeleton } from '@mui/lab';
import { Download } from '@mui/icons-material';
import { useDebouncedCallback } from 'use-debounce'
import { getData } from "../../api/dataSlice"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import Events from './Events'
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import dayjs from 'dayjs';
import AddTag from './AddTag';
import SearchTag from './SearchTag';
import i18next from 'i18next';
import Diary from './Diary';

export default function Calendar(props) {
    
    const { LAYOUT: { TAGGING, DIARY } } = window.conf
    const { t } = useTranslation()
    const { tagData, openTagModal, onOpenTagModal, onCloseTagModal, initialSiderWidth, refSider, openDownload, timestamp, setTimestamp, loadingEvents, footerHeightCalendar, shouldDisableDate, loading, shouldDisableTime } = props
    const { source, events } = useSelector(getData)
    const { isPortrait } = useMobileOrientation()

    const [scrollbarWidth, setScrollbarWidth] = useState(0)
    const [searchDataUpdated, setSearchDataUpdated] = useState(false);
    
    const daySize = 38
    const minDate = dayjs(source?.[0]?.[0]?.[0])
    const maxDate = dayjs(source?.[0]?.[source[0].length-1]?.[0])
    const updateScrollbarWidth = useDebouncedCallback(() => setScrollbarWidth(refSider?.current?.offsetWidth - refSider?.current?.clientWidth), 500)
    const disableTaggingButtons = !TAGGING
    const disableDiaryButton = !DIARY.ENABLED

    useEffect(() => {
        updateScrollbarWidth()
        window.addEventListener('resize', updateScrollbarWidth)
        return () => window.removeEventListener('resize', updateScrollbarWidth)
    }, [updateScrollbarWidth, events])

    if (isMobile && isPortrait) {
        return (
            <>
                <LocalizationProvider 
                    dateAdapter={DayjsAdapter}
                    locale={i18next.language}
                >
                    <DateTimePicker
                        ampm={false}
                        label={t("date.date_n_time")}
                        inputFormat={(t("date.format.full_date_time"))}
                        mask={t("date.mask.full_date_time")}
                        renderInput={props => <TextField {...props} sx={{ width: "100%" }} />}
                        value={timestamp}
                        onChange={setTimestamp}
                        cancelText={t("actions.cancel")}
                        toolbarTitle={t("date.select_datetime")}
                        minDate={minDate}
                        maxDate={maxDate}
                        loading={loading}
                        shouldDisableDate={shouldDisableDate}
                        shouldDisableTime={shouldDisableTime}
                        renderLoading={() => <CalendarPickerSkeleton />}
                        showTodayButton
                        todayText={t("date.today")}
                    />
                </LocalizationProvider>

                <Divider sx={{ userSelect: "none" }}>{t("events.events")}</Divider>

                <Events 
                    footerHeightCalendar={footerHeightCalendar}
                    events={events}
                    onOpenTagModal={onOpenTagModal}
                    loading={loading || loadingEvents}
                />

                <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    margin: 0,
                    padding: 0,
                    marginTop: 8
                }}>
                    <Diary
                        loading={loading}
                        timestamp={timestamp}
                        disabled={disableDiaryButton}
                    />
                    
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'flex-end',
                        margin: 0,
                        padding: 0
                    }}>
                        <SearchTag
                            loading={loading}
                            minDate={minDate}
                            maxDate={maxDate}
                            timestamp={timestamp}
                            onOpenTagModal={onOpenTagModal}
                            isSearchUpdated={searchDataUpdated}
                            setSearchUpdated={setSearchDataUpdated}
                            disabled={disableTaggingButtons}
                        />

                        <AddTag
                            loading={loading}
                            openTagModal={openTagModal}
                            onOpenTagModal={onOpenTagModal}
                            onCloseTagModal={onCloseTagModal}
                            data={tagData}
                            timestamp={timestamp}
                            updateSearch={setSearchDataUpdated}
                            disabled={disableTaggingButtons}
                        />
                    </Box>
                    
                </Box>
            </>
        )
    } else {
        return (
            <>
                <LocalizationProvider 
                    dateAdapter={DayjsAdapter}
                    locale={i18next.language}
                >
                    {isMobile
                    ? <Box sx={{ pt: 2, width: "calc(100% - 32px)" }}>
                        <DatePicker
                            label={t("date.date")}
                            inputFormat={(t("date.format.full"))}
                            mask={t("date.mask.full")}
                            renderInput={props => <TextField {...props} sx={{ width: "100%" }} />}
                            value={timestamp}
                            onChange={setTimestamp}
                            cancelText={t("actions.cancel")}
                            minDate={minDate}
                            maxDate={maxDate}
                            loading={loading}
                            shouldDisableDate={shouldDisableDate}
                            renderLoading={() => <CalendarPickerSkeleton />}
                            showTodayButton
                            todayText={t("date.today")}
                            showToolbar={false}
                        />
                    </Box>
                    : <Box
                        sx={{
                            "& > div": {
                                minWidth: initialSiderWidth - scrollbarWidth - 33,
                                px: 2,
                                pt: 2
                            },
                            "& > div > div": {
                                border: "1px solid",
                                borderColor: "#BDBDBD",
                                borderRadius: "4px"
                            },
                            "& > div > div, & > div > div > div, & .MuiCalendarPicker-root": {
                                width: initialSiderWidth - scrollbarWidth - 33,
                                height: initialSiderWidth + 3,
                            },
                            "& .MuiTypography-caption": {
                                width: daySize,
                                margin: 0
                            },
                            "& .PrivatePickersSlideTransition-root": {
                                minHeight: daySize * 6,
                                overflow: "hidden"
                            },
                            '& .PrivatePickersSlideTransition-root [role="row"]': {
                                margin: 0
                            },
                            "& .MuiPickersDay-dayWithMargin": {
                                margin: 0
                            },
                            "& .MuiPickersDay-root": {
                                width: daySize,
                                height: daySize
                            },
                            "& .MuiYearPicker-root": {
                                justifyContent: "center"
                            },
                            "& .MuiCalendarPicker-viewTransitionContainer": {
                                overflow: "hidden"
                            }
                        }}
                    >
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            renderInput={() => {}}
                            value={timestamp}
                            onChange={setTimestamp}
                            renderDay={(day, _value, DayComponentProps) => {
                                // https://mui.com/x/react-date-pickers/date-picker/#dynamic-data=
                                return (
                                    <PickersDay 
                                        {...DayComponentProps} 
                                    />
                                );
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            loading={loading}
                            shouldDisableDate={shouldDisableDate}
                            renderLoading={() => <CalendarPickerSkeleton />}
                        />
                    </Box>}
                </LocalizationProvider>

                <Stack 
                    sx={{ p: 2, flexGrow: 1 }} 
                    spacing={2}
                >

                    <Divider sx={{ height: "11px", pb: "11px", userSelect: "none" }}>
                        {t("events.events")}
                    </Divider>

                    <Events 
                        footerHeightCalendar={footerHeightCalendar}
                        events={events}
                        onOpenTagModal={onOpenTagModal}
                        loading={loading || loadingEvents}
                    />

                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                        margin: 0,
                        padding: 0,
                        marginTop: 8
                    }}>
                        <Diary 
                            loading={loading}
                            timestamp={timestamp}
                            disabled={disableDiaryButton}
                        />
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            justifyContent: 'flex-end',
                            margin: 0,
                            padding: 0
                        }}>
                          <SearchTag
                              loading={loading}
                              minDate={minDate}
                              maxDate={maxDate}
                              timestamp={timestamp}
                              onOpenTagModal={onOpenTagModal}
                              isSearchUpdated={searchDataUpdated}
                              setSearchUpdated={setSearchDataUpdated}
                              disabled={disableTaggingButtons}
                          />

                          <AddTag
                                loading={loading}
                                openTagModal={openTagModal}
                                onOpenTagModal={onOpenTagModal}
                                onCloseTagModal={onCloseTagModal}
                                data={tagData}
                                timestamp={timestamp}
                                updateSearch={setSearchDataUpdated}
                                disabled={disableTaggingButtons}
                          />
                        </Box>
                    </Box>

                    <Button
                        variant="contained"
                        startIcon={<Download />}
                        size="large"
                        onClick={openDownload}
                        fullWidth
                        disabled={loading}
                    >
                        {t("actions.download")}
                    </Button>

                </Stack>
            </>
        )
    }
}
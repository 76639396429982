import 'video.js/dist/video-js.css';
import videojs from "video.js";
import de from 'video.js/dist/lang/de.json';
import nl from 'video.js/dist/lang/nl.json';
import i18next from "i18next";
import { LinearProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState, useCallback } from "react";
import { saveFile } from "../Utilities";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

if (i18next.language !== 'en') {
    videojs.addLanguage('de', de)
    videojs.addLanguage('nl', nl)
}

const VideoJs = (props) => {

    const { t } = useTranslation()
    const { camera: { timelapse: source = "", name: cameraName = "", id: cameraId = 0 } } = props
    const { CAMERAS, LAYOUT: { WRAP_LIMIT, TITLE }, DEBUG } = window.conf
    
    const [isDownloading, setIsDownloading] = useState(false)

    const videoRef = useRef(null)
    const playerRef = useRef(null)
    const moreThanOneCam = CAMERAS.length > 1
    const manyCams = CAMERAS.length > WRAP_LIMIT
    const camera = cameraName 
        ? `${cameraName}` 
        : moreThanOneCam 
            ? `${t("events.camera")} ${cameraId + 1}` 
            : ""
    const title = TITLE
        ? camera
            ? `${TITLE}_`
            : TITLE
        : ""
    const options = {
        autoplay: false,
        language: i18next.language,
        controls: true,
        responsive: true,
        preload: isMobile ? "metadata" : "auto",
        nativeControlsForTouch: true,
        sources: [{ src: source }],
        playbackRates: [ 0.5, 1, 1.5, 2 ],
        controlBar: { 'volumePanel': false },
        experimentalSvgIcons: true
    }
    const sourceLowQuality = source.replace('timelapsecomplete.mp4', 'timelapsecomplete_reduced.mp4')

    const downloadVideo = useCallback((url) => {
        setIsDownloading(true);
        fetch(url)
        .then(res => res.ok ? res.blob() : console.error(res.status, res.statusText))  
        .then(blob => saveFile(blob, `${title}${camera}.mp4`))
        .catch(error => console.error(error))
        .finally(() => setIsDownloading(false))
    }, [cameraName]) // eslint-disable-line react-hooks/exhaustive-deps

    const takeSnapshot = useCallback((video)=> {
        const canvas = document.createElement('canvas')

        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

        const image = canvas.toDataURL('image/jpeg', 1.0)

        saveFile(image, `${title}${camera}.jpg`) 
    }, [cameraName]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let lastSeekTime = 0;
        let player = playerRef.current;

        if (!player) {

            const videoElement = videoRef.current

            if (!videoElement) return

            player = playerRef.current = videojs(videoElement, options)

            const playerControlBar = player.getChild('ControlBar')
                        
            playerControlBar.addChild('button', {
                controlText: t("actions.screenshot"),
                className: 'vjs-snapshot-button',
                clickHandler: () => takeSnapshot(videoElement)
            }, 13)
            
            playerControlBar.addChild('button', {
                controlText: t("actions.download"),
                className: 'vjs-download-button',
                clickHandler: () => downloadVideo(source)
            }, 14)

            const vjsMenuButtonComponent = videojs.getComponent('MenuButton');
            const vjsMenuItemComponent = videojs.getComponent('MenuItem');

            class QualityMenu extends vjsMenuButtonComponent {

                constructor(player, options = {}) {
                    super(player, options);

                    const textElement = videojs.dom.createEl('span', {
                        className: 'vjs-quality-menu-text',
                        innerHTML: "Auto"
                    });

                    this.el().appendChild(textElement);
                    this.el().title = t("timelapse.quality");

                    this.addClass('vjs-quality-menu vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button');
                    this.isSwitchingQuality = false;
                }

                createItems() {
                    const qualities = [
                        { label: t("timelapse.original"), src: source },
                        { label: t("timelapse.reduced"), src: sourceLowQuality }
                    ];
            
                    return qualities.map(quality => {
                        const menuItem = new vjsMenuItemComponent(this.player_, {
                            label: quality.label,
                            src: quality.src,
                            selectable: true,
                            className: "vjs-quality-menu-item",
                            selected: quality.label === t("timelapse.original")
                        });
            
                        menuItem.on('click', () => {
                            this.updateMenuSelection(menuItem);
                            this.updateMenuText(menuItem);
                            this.switchQuality(menuItem);
                        });
            
                        return menuItem;
                    });
                }

                switchQuality(selectedItem) {
                    this.isSwitchingQuality = true;
                    const currentTime = this.player_.currentTime();
                    const isPlaying = !this.player_.paused();
                    this.player_.src(selectedItem.options_.src);
                    this.player_.one('loadeddata', () => {
                        this.player_.currentTime(currentTime);
                        if (isPlaying) {
                            this.player_.play();
                        }
                        this.isSwitchingQuality = false;
                    });
                }

                switchQualityToReduced() {
                    const lowQualityItem = this.menu.children_.find(item => item.options_.label === t("timelapse.reduced"));
                
                    if (lowQualityItem) {
                        this.updateMenuSelection(lowQualityItem);
                        this.updateMenuText(lowQualityItem);
                        this.switchQuality(lowQualityItem);
                    }
                }

                updateMenuSelection(selectedItem) {
                    this.menu.children_.forEach(item => item.selected(false));
                    selectedItem.selected(true);
                }

                updateMenuText(selectedItem) {
                    const textElement = this.el().querySelector('.vjs-quality-menu-text');
                    if (textElement) {
                        textElement.innerHTML = selectedItem.options_.label;
                    }
                }
            }

            videojs.registerComponent('QualityMenu', QualityMenu);

            playerControlBar?.addChild('QualityMenu', {}, 15)

            const qualityMenu = player.controlBar.getChild('QualityMenu');

            player.on("seeking", () => {
                lastSeekTime = Date.now();
            });

            player.on('waiting', () => {
                const now = Date.now();
                const isManualSeek = now - lastSeekTime < 500;

                if (isManualSeek && DEBUG) {
                    console.log("Manual seek detected, quality remains unchanged.");
                }

                if (!isManualSeek && !qualityMenu?.isSwitchingQuality && player.currentSrc() === source) {
                    DEBUG && console.log("Buffering detected, switching to low quality.");
                    qualityMenu.switchQualityToReduced();
                }
            });

        } else {
            player.src(options.sources);
        }

    }, [source, videoRef]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const player = playerRef.current;
    
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const Progress = () => {
        return (
            <div style={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 35
            }}>
                <Typography variant="caption" fontSize={16} color="white">
                    {t("actions.downloading")}...
                </Typography>
                <LinearProgress style={{ width: '99%' }} />
            </div>
        )
    }

    return (
        <div style={{
            width: "100%",
            height: manyCams ? "98%" : '100%',
            display: 'flex',
            alignItems: 'center',
        }}>
            <video
                ref={videoRef}
                className="video-js vjs-big-play-centered vjs-full-height"
                crossOrigin="anonymous" // needed for snapshot on local environment
            />
            {isDownloading && <Progress />}
        </div>
    );
}

export default VideoJs;
import dayjs from "dayjs"
import TotalVisitsChart from "./TotalVisitsChart";
import BarChart from "./BarChart";
import { QueryStats } from "@mui/icons-material";
import { IconButton, Popover, Tooltip, Typography, Grid, Divider, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDevicesQuery, useGetLocationsQuery, useGetVisitsQuery } from "../../api/statsApi";

export default function Stats({ invertColors, isVisible }) {

    const [anchorEl, setAnchorEl] = useState(null)
    const [days, setDays] = useState(undefined) // undefined | helps avoid setting args for the first time in first useEffect
    const [args, setArgs] = useState({
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD').toString(),
        endDate: dayjs().format('YYYY-MM-DD').toString()
    })
    const [timespan, setTimespan] = useState(days || 30) // days
    
    const { t } = useTranslation()
    const { data: visits, isFetching: visitsFetching, error: visitsError } = useGetVisitsQuery(args)
    const { data: locations, isFetching: locationsFetching, error: locationsError } = useGetLocationsQuery(args)
    const { data: devices, isFetching: devicesFetching, error: devicesError } = useGetDevicesQuery(args)

    const error = visitsError || locationsError || devicesError
    const loading = visitsFetching || locationsFetching || devicesFetching
    const hasVisits = visits?.length > 0 
    const dateFormat = 'YYYY-MM-DD'
    let content = t("stats.no_visitors")

    if (hasVisits) {
        content = `${visits[0].unique_visits} ${t("stats.total_visitors")}`
    }

    if (error) {
        content = t("msg.failed")
    }

    useEffect(() => {
        if (days === 1) { // yesterday
            setArgs({
                startDate: dayjs().subtract(1, 'day').format(dateFormat).toString(),
                endDate: dayjs().subtract(1, 'day').format(dateFormat).toString(),
            })
        } else if (days === 7) { // last 7 days
            setArgs({
                startDate: dayjs().subtract(1, 'week').format(dateFormat).toString(),
                endDate: dayjs().format(dateFormat).toString(),
            })
        } else if (days === 30) { // last 30 days
            setArgs({
                startDate: dayjs().subtract(1, 'month').format(dateFormat).toString(),
                endDate: dayjs().format(dateFormat).toString(),
            })
        } else if(days > 30) { // last 12 months
            setArgs({
                startDate: dayjs().subtract(1, 'year').format(dateFormat).toString(),
                endDate: dayjs().format(dateFormat).toString(),
            })
        }
    }, [days])

    useEffect(() => {
        if (error) {
            console.error(error?.data?.msg)
        }
    }, [error])
    
    const handleOpen = (event) => {
        if (anchorEl !== event?.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    const handleChangeTimespan = (event) => {
        setDays(event.target.value)
        setTimespan(event.target.value)
    }

    return (
        isVisible && (
            <Fragment>

                <Tooltip title={t("stats.tooltip")} arrow>
                    <IconButton
                        sx={{
                            p: 0,
                            pr: { xs: '16px', md: '4px' },
                            pl: { xs: 0, md: '16px' },
                            pb: "1px",
                            color: invertColors ? 'white' : 'primary.main'
                        }}
                        onClick={handleOpen}
                    >
                        <QueryStats sx={{ fontSize: '1.35rem' }} />
                    </IconButton>
                </Tooltip>

                <Popover
                    id={Boolean(anchorEl) ? 'stats-popover' : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <Grid
                        container
                        rowSpacing={2}
                        direction="column"
                        sx={{
                            p: 2,
                            pb: error || !hasVisits ? 2 : 0,
                            textAlign: "center",
                            userSelect: "none",
                            alignItems: error ? "center" : undefined
                        }}
                    >

                        <Grid
                            item
                            justifyContent="space-between"
                            alignItems="center"
                            display="flex"
                        >

                            <Typography sx={{ 
                                fontSize: "1rem", 
                                color: "#003366",
                                mr: error ? 0 : 2 
                            }}>
                                {content}
                            </Typography>

                            {!error && 
                            <FormControl size="small">
                                <Select
                                    value={timespan}
                                    onChange={handleChangeTimespan}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={1}>{t("date.yesterday")}</MenuItem>
                                    <MenuItem value={7}>{t("date.last_7_days")}</MenuItem>
                                    <MenuItem value={30}>{t("date.last_30_days")}</MenuItem>
                                    <MenuItem value={365}>{t("date.Last_12_months")}</MenuItem>
                                </Select>
                            </FormControl>}

                        </Grid>

                        {hasVisits &&
                        <Fragment>
                            <Grid item>
                                <TotalVisitsChart args={args} />
                            </Grid>
                            
                            <Divider />

                            <Grid item>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    sx={{ pb: 2 }}
                                >
                                    <BarChart
                                        title={t("stats.locations")}
                                        data={locations}
                                        loading={loading}
                                        error={error}
                                        selectedKey="geo_country"
                                    />

                                    <Divider orientation="vertical" flexItem />
                                
                                    <BarChart
                                        title={t("stats.devices")}
                                        data={devices}
                                        loading={loading}
                                        error={error}
                                        selectedKey="device_type"
                                    />
                                </Stack>
                            </Grid>
                        </Fragment>}

                    </Grid>
                </Popover>

            </Fragment>
        )
    )
}
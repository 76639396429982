import React, { useState, useEffect } from 'react';
import { Document as DocumentPdf, Page, Text, View, StyleSheet, Font, Svg, Path, Image as ImagePdf } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Air, WbSunny, Thermostat, LocalShipping, Nightlight, ThermostatAuto, AcUnit, TornadoRounded, ListAlt, EmojiObjects, PersonAdd, PersonRemove, WaterDropOutlined, WaterDrop, WarningAmberOutlined, People } from '@mui/icons-material';
import { renderToStaticMarkup } from 'react-dom/server';
import { checkFile } from '../../components/Utilities';

export default function Document({ data, pdf }) {

    const { t } = useTranslation()
    const { title, lastUpdate, weather, events, reportPath, enableAI } = data
    
    const [ reportVisible, setReportVisible ] = useState(true)
   
    Font.register({
        family: 'Roboto',
        fonts: [
            { src: "https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-300-normal.ttf" },
            { src: "https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-400-normal.ttf" },
            { src: "https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-500-normal.ttf" },
            { src: "https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-700-normal.ttf" }
        ]
    })

    function extractPathAttributeValue(svgString) {
        const pathStart = svgString.indexOf('<path ')
        if (pathStart !== -1) {
            const dStart = svgString.indexOf('d="', pathStart)
            if (dStart !== -1) {
                const dEnd = svgString.indexOf('"', dStart + 3) // +3 to skip "d='"
                if (dEnd !== -1) {
                    return svgString.substring(dStart + 3, dEnd)
                }
            }
        }
        return null
    }

    const styles = StyleSheet.create({
        page: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            backgroundColor: 'white',
            paddingLeft: pdf ? 60 : 20,
            paddingTop: pdf ? 40 : 20,
            paddingBottom: pdf ? 40 : 20,
            paddingRight: pdf ? 60 : 20,
            fontSize: pdf ? 12 : 14,
            fontFamily: 'Roboto',
        },
        title: {
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
        },
        subtitle: {
            marginTop: 8,
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
        },
        subsubtitle: {
            marginTop: 16,
            fontFamily: 'Roboto',
        },
        heading: {
            fontSize: 16,
            fontWeight: 'bold',
            marginTop: 20,
            padding: '5px 0',
            flexGrow: 1,
            fontFamily: 'Roboto',
        },
        row: {
            display: 'flex',
            marginTop: 5,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexGrow: 1,
            position: 'relative',
            minHeight: '20px'
        },
        report: {
            marginTop: pdf ? 60 : 20,
            flexGrow: 1,
            alignSelf: 'center',
            width: "100%"
        },
        list: {
            alignItems: 'flex-start'
        },
        rowIcon: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '30px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '5px',
            height: "20px"
        },
        rowIconList: {
            height: "15px"
        },
        rowTitle: {
            position: 'absolute',
            left: '30px',
            fontFamily: 'Roboto',
        },
        rowTitleWeather: {
            width: '270px'
        },
        rowTitleEvents: {
            width: '340px'
        },
        rowTitleList: {
            top: pdf ? "3px" : "2px"
        },
        rowDescription: {
            position: 'relative',
            width: '100%',
            fontFamily: 'Roboto',
            whiteSpace: 'pre'
        },
        rowDescriptionWeather: {
            marginLeft: pdf ? "230px" : '300px'
        },
        rowDescriptionEvents: {
            marginLeft: pdf ? "320px" : '370px'
        },
        rowDescriptionList: {
            top: pdf ? "3px" : "2px"
        }
    })

    const Div = ({ children, style, inlineBlock }) => (
        <div style={{
            ...style,
            ...(inlineBlock && { display: 'inline-block' })
        }}>
            {children}
        </div>
    )

    const Img = ({ src, style }) => (
        <img
            style={{
                ...style,
                width: '100%',
                height: '100%',
            }}
            alt="construction-diary"
            src={src}
        />
    )

    const SvgComponent = ({ children }) => pdf ?
        <Svg 
            viewBox="0 0 24 24" 
            style={{
                width: "20px",
                height: "20px"
            }}
        >
            <Path fill="#000" d={extractPathAttributeValue(renderToStaticMarkup(children))} />
        </Svg> :
        children

    const DocumentComponent = pdf ? DocumentPdf : Div
    const PageComponent = pdf ? Page : Div
    const ViewComponent = pdf ? View : Div
    const TextComponent = pdf ? Text : Div
    const ImageComponent = pdf ? ImagePdf : Img

    useEffect(() => {
        const checkImage = async() => {
            const { exists, contentType } = await checkFile(data.reportPath)
            if (exists && contentType === 'image') {
                setReportVisible(exists)
            }
        }
    
        checkImage()
    }, [data.reportPath])

    return (
        <DocumentComponent inlineBlock={true}>
            <PageComponent size="A4" style={styles.page}>

                <ViewComponent style={styles.title}>
                    <TextComponent>
                        {t('events.construction_diary.construction_diary')}
                    </TextComponent>
                </ViewComponent>

                <ViewComponent style={styles.subtitle}>
                    <TextComponent style={styles.bold}>
                        {title}
                    </TextComponent>
                </ViewComponent>

                {lastUpdate !== "" &&
                lastUpdate !== "Invalid Date" && (
                    <ViewComponent style={styles.subsubtitle}> 
                        <TextComponent>
                            {t('msg.last_update')}: {lastUpdate}{t('date.oclock')}
                        </TextComponent>
                    </ViewComponent>
                )}

                <ViewComponent>

                    <ViewComponent style={styles.heading}>
                        <TextComponent>{t('weather.weather')}</TextComponent>
                    </ViewComponent>

                    {weather.sunrise === "" && 
                    weather.sunset === "" && 
                    weather.min_temperature === "" && 
                    weather.max_temperature === "" && 
                    weather.average_temperature === "" && 
                    weather.max_wind_speed === "" && 
                    weather.min_wind_speed === "" && 
                    weather.max_humidity === "" && 
                    weather.min_humidity === "" && 
                    weather.forecast?.length === 0 && 
                    weather.summary?.length === 0 && (
                        <ViewComponent style={styles.rowTitleWeather}>
                            <TextComponent>{t('msg.no_weather')}</TextComponent>
                        </ViewComponent>
                    )}

                    {weather.sunrise !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <WbSunny fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.sunrise')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.sunrise}{t('date.oclock')}</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.sunset !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <Nightlight fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.sunset')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.sunset}{t('date.oclock')}</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}
                    
                    {weather.min_temperature !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <AcUnit fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.min_temperature')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.min_temperature} °C</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.max_temperature !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <Thermostat fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.max_temperature')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.max_temperature} °C</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.average_temperature !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <ThermostatAuto fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.average_temperature')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.average_temperature} °C</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.min_wind_speed !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <Air fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.min_wind')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.min_wind_speed} km/h</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.max_wind_speed !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <TornadoRounded fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.max_wind')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.max_wind_speed} km/h</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.min_humidity !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <WaterDropOutlined fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.min_humidity')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.min_humidity} %</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.max_humidity !== "" && (
                        <ViewComponent style={styles.row}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <WaterDrop fontSize='small' />
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather }}>
                                <TextComponent>{t('weather.max_humidity')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather }}>
                                <TextComponent>{weather.max_humidity} %</TextComponent>
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.summary?.length > 0 && (
                        <ViewComponent style={{ ...styles.row, ...styles.list }}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <ListAlt fontSize='small' />    
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather, ...styles.rowTitleList }}>
                                <TextComponent>{t('weather.summary')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather, ...styles.rowDescriptionList }}>
                                {weather.summary.map((elem, index) => 
                                    <TextComponent key={`weather-summary-${index}`}>
                                        {elem}
                                    </TextComponent>
                                )}
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.alerts?.length > 0 && (
                        <ViewComponent style={{ ...styles.row, ...styles.list }}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <WarningAmberOutlined fontSize='small' />    
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather, ...styles.rowTitleList }}>
                                <TextComponent>{t('weather.alerts.alerts')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather, ...styles.rowDescriptionList }}>
                                {weather.alerts.map((elem, index) => 
                                    <TextComponent key={`weather-alerts-${index}`}>
                                        {elem}
                                    </TextComponent>
                                )}
                            </ViewComponent>
                        </ViewComponent>
                    )}

                    {weather.forecast?.length > 0 && (
                        <ViewComponent style={{ ...styles.row, ...styles.list }}>
                            <ViewComponent style={styles.rowIcon}>
                                <SvgComponent>
                                    <EmojiObjects fontSize='small' />    
                                </SvgComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleWeather, ...styles.rowTitleList }}>
                                <TextComponent>{t('weather.forecast')}:</TextComponent>
                            </ViewComponent>
                            <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionWeather, ...styles.rowDescriptionList }}>
                                {weather.forecast.map((forecast, index) => 
                                    <TextComponent key={`weather-forecast-${index}`}>
                                        {forecast.day}: {forecast.description}, {Math.round(forecast.temp.max)} °C / {Math.round(forecast.temp.min)} °C
                                    </TextComponent>
                                )}
                            </ViewComponent>
                        </ViewComponent>
                    )}

                </ViewComponent>

                {enableAI && (
                    <ViewComponent>

                        <ViewComponent style={styles.heading}>
                            <TextComponent>{t('events.events')}</TextComponent>
                        </ViewComponent>

                        {events.start_of_work === "" && 
                        events.end_of_work === "" && 
                        events.number_of_persons === "" && 
                        Object.keys(events.vehicles).length === 0 && (
                            <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleEvents }}>
                                <TextComponent>{t('msg.no_events')}</TextComponent>
                            </ViewComponent>
                        )}

                        {events.start_of_work !== "" && (
                            <ViewComponent style={styles.row}>
                                <ViewComponent style={styles.rowIcon}>
                                    <SvgComponent>
                                        <PersonAdd fontSize='small' />    
                                    </SvgComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleEvents }}>
                                    <TextComponent>{t('events.construction_diary.start_of_work')}:</TextComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionEvents }}>
                                    <TextComponent>{events.start_of_work}{t('date.oclock')}</TextComponent>
                                </ViewComponent>
                            </ViewComponent>
                        )}

                        {events.end_of_work !== "" && (
                            <ViewComponent style={styles.row}>
                                <ViewComponent style={styles.rowIcon}>
                                    <SvgComponent>
                                        <PersonRemove fontSize='small' />    
                                    </SvgComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleEvents }}>
                                    <TextComponent>{t('events.construction_diary.end_of_work')}:</TextComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionEvents }}>
                                    <TextComponent>{events.end_of_work}{t('date.oclock')}</TextComponent>
                                </ViewComponent>
                            </ViewComponent>
                        )}

                        {events.maxPeople !== "" && (
                            <ViewComponent style={styles.row}>
                                <ViewComponent style={styles.rowIcon}>
                                    <SvgComponent>
                                        <People fontSize='small' />    
                                    </SvgComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleEvents }}>
                                    <TextComponent>{t('events.construction_diary.number_of_persons')}:</TextComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionEvents }}>
                                    <TextComponent>{events.maxPeople}{events.maxPeopleTime !== "" && ` (${events.maxPeopleTime}${t('date.oclock')})`}</TextComponent>
                                </ViewComponent>
                            </ViewComponent>
                        )}

                        {Object.keys(events.vehicles).length > 0 && (
                            <ViewComponent style={{ ...styles.row, ...styles.list }}>
                                <ViewComponent style={styles.rowIcon}>
                                    <SvgComponent>
                                        <LocalShipping fontSize='small' />    
                                    </SvgComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowTitle, ...styles.rowTitleEvents, ...styles.rowTitleList }}>
                                    <TextComponent>{t('events.construction_diary.number_of_vehicles')}:</TextComponent>
                                </ViewComponent>
                                <ViewComponent style={{ ...styles.rowDescription, ...styles.rowDescriptionEvents, ...styles.rowDescriptionList }}>
                                    {events.vehicles["concreteMixer"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.concrete_mixer')}: {events.vehicles["concreteMixer"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["truck"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.truck')}: {events.vehicles["truck"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["excavator"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.excavator')}: {events.vehicles["excavator"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["concretePump"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.concrete_pump')}: {events.vehicles["concretePump"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["wheelLoader"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.wheel_loader')}: {events.vehicles.wheelLoader}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["car"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.car')}: {events.vehicles["car"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["crane type"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.crane_type')}: {events.vehicles["crane type"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["utility vehicle"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.utility_vehicle')}: {events.vehicles["utility vehicle"]}
                                        </TextComponent>
                                    )}
                                    {events.vehicles["basketLift"] && (
                                        <TextComponent>
                                            {t('events.construction_diary.vehicles.basket_lift')}: {events.vehicles["basketLift"]}
                                        </TextComponent>
                                    )}
                                </ViewComponent>
                            </ViewComponent>
                        )}

                    </ViewComponent>
                )}

                {reportVisible && (
                    <ViewComponent style={styles.report}>
                        <ImageComponent src={reportPath} />
                    </ViewComponent>
                )}

            </PageComponent>
        </DocumentComponent>
    )
}
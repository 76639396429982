import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ArrowLeft from "../../assets/arrow-left.svg";
import ArrowRight from "../../assets/arrow-right.svg";

export const ViewType = {
    Image: 'Camera',
    Streaming: 'Streaming'
}

export default function StaticLiveSwitch({ type, onClick }) {
    
    const { t } = useTranslation()

    const styles = {
        buttonStyle: {
            height: isMobile ? 78 : 110,
            width: isMobile ? 78 : 110,
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        buttonText: {
            color: 'white',
            fontSize: isMobile ? '0.8rem' : '1rem',
            textTransform: 'uppercase',
            paddingBottom: isMobile ? 0 : 3,
        }
    }

    return (
        <>
            {type === ViewType.Image
            ? <div
                className={isMobile ? "live-camera-switch full-opacity" : "live-camera-switch"}
                onClick={onClick}
                style={{
                    ...styles.buttonStyle,
                    backgroundImage: `url(${ArrowLeft})`,
                    left: 5,
                }}
            >
                <span style={{
                    ...styles.buttonText,
                    textAlign: 'center',
                    marginLeft: isMobile ? '1.6rem' : '2.3125rem'
                }}>
                    {t("image.live_switch1")}
                </span>
            </div>
            : <div
                className={isMobile ? "live-camera-switch full-opacity" : "live-camera-switch"}
                onClick={onClick}
                style={{
                    ...styles.buttonStyle,
                    backgroundImage: `url(${ArrowRight})`,
                    right: 5,
                }}
            >
                <span style={{
                    ...styles.buttonText,
                    textAlign: 'left',
                    paddingLeft: isMobile? 6 : 14,
                }}>
                    {t("image.live_switch2")}
                </span>
            </div>}
        </>
    )
}
import React, { useEffect, useState, Fragment } from 'react';
import { databaseDateToFilename, useStatistics, useWindowDimensions } from "./Utilities"
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { getData } from '../api/dataSlice';
import { useSelector } from "react-redux"
import Grid from '@mui/material/Grid'
import Static from "./live/Static"
import StaticWithLive from './live/StaticWithLive';
import Timelapse from "./timelapse/Timelapse"
import Archive from "./archive/Archive"
import ControlsArchive from './archive/Controls'
import ControlsTimelapse from './timelapse/Controls';

export default function Content({ component }) {

    const { CAMERAS, LAYOUT: { WRAP_LIMIT, TIMELAPSE }, DEBUG } = window.conf
    const { isPortrait } = useMobileOrientation()
    const { index, source, initialFooterHeight } = useSelector(getData)
    const { contentHeight } = useWindowDimensions()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [paddingFooter, setPaddingFooter] = useState(0)
    
    const drawerBleeding = 22
    const initialSiderWidth = 332
    const camCount = CAMERAS.length
    const manyCameras = camCount > WRAP_LIMIT
    const timelapseSider = !!TIMELAPSE
    const hasControls = timelapseSider ? 
        (component === 2 || component === 3) :
        component === 3
    const isStatisticsLoaded = useStatistics()

    useEffect(() => {
        isStatisticsLoaded && DEBUG && console.log("[etracker] Successfully initialized")
    }, [isStatisticsLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    function renderComponent(camera) { 

        switch (component) {
            // HOME
            case 0:
                return (
                    <Static
                        contentHeight={contentHeight} 
                        camera={camera}
                    />
                )
            // LIVE
            case 1:
                // If camera contains streaming video then render the ImageWithLiveView to switch between the live and image modes
                if (camera.video) {
                    return (
                        <StaticWithLive
                            contentHeight={contentHeight}
                            camera={camera}
                        />
                    )
                } else {
                    return (
                        <Static
                            contentHeight={contentHeight} 
                            camera={camera}
                        />
                    )
                }
            // TIMELAPSE
            case 2:
                return (
                    <Timelapse
                        contentHeight={timelapseSider ? (isMobile && isPortrait ? contentHeight - initialFooterHeight - 6 : contentHeight) : contentHeight}
                        camera={camera}
                    />
                )
            // ARCHIVE
            case 3:
                var imageSource = camera.image

                if (source && Number.isFinite(index)) {
                    imageSource = camera.download + databaseDateToFilename(source[0]?.[index]?.[1]?.[camera.id]?.datum)
                }

                return (
                    <Archive
                        contentHeight={isMobile && isPortrait ? contentHeight - initialFooterHeight : contentHeight} 
                        camera={camera}
                        imageSource={imageSource}
                        imageIndex={index}
                        imagesList={source ? source : []}
                        isDrawerOpen={isDrawerOpen}
                        paddingFooter={paddingFooter}
                    />
                )
            default:
                break;
        }
    }

    return (
        <Fragment>
            <Grid 
                container
                columns={{ 
                    xs: 1, 
                    md: 2,
                    lg: manyCameras ? 3 : 2, 
                    xl: manyCameras ? 
                        4 : 
                        camCount > 2 ?
                            3 :
                            2
                }}
                sx={{ 
                    marginBottom: hasControls && isMobile && isPortrait ? `${initialFooterHeight}px` : 0,
                    width: hasControls
                            ? isMobile 
                                ? !isPortrait 
                                    ? !isDrawerOpen
                                        ? `calc(100% - ${drawerBleeding}px)` 
                                        : `calc(100% - ${initialSiderWidth}px)`
                                    : '100%'
                                : `calc(100% - ${initialSiderWidth}px)`
                            : '100%',
                    transition: '0.2s linear',
                    background: "black",
                    overflowX: "hidden",
                    paddingBottom: hasControls && isMobile && isPortrait ? `${paddingFooter}px` : 0,
                }}
            >
                {CAMERAS.map((camera, index) => (
                    <Grid 
                        item 
                        key={index}
                        xs={1}
                        md={camCount % 2 // amount of images is odd (e.g. 1, 3 or 5)
                            && index === camCount-1 // image is last element of the array
                            && !manyCameras
                            ? 2 // extend over both columns
                            : 1
                        }
                        xl={camCount > 2 ? 1 : undefined}
                        sx={{ 
                            backgroundColor: "black", // TODO: change background color of images/video
                            overflow: "hidden"
                        }}
                    >
                        {renderComponent(camera)}
                    </Grid>
                ))}   
            </Grid>

            {/* Timelapse has Controls */}

            {component === 2 && timelapseSider && (
                <ControlsTimelapse
                    initialSiderWidth={initialSiderWidth}
                    onOpenSideDrawer={setIsDrawerOpen}
                    setPaddingFooter={setPaddingFooter}
                    drawerBleeding={drawerBleeding}
                />
            )}

            {/* Archive has Controls */}

            {component === 3 && (
                <ControlsArchive
                    initialSiderWidth={initialSiderWidth}
                    onOpenSideDrawer={setIsDrawerOpen}
                    setPaddingFooter={setPaddingFooter}
                    drawerBleeding={drawerBleeding}
                />
            )}

        </Fragment>
    )
}
import { useState } from "react";
import Static from "./Static";
import Live from "./Live";

export default function StaticWithLive({ contentHeight, camera }) {
    const [showLive, setShowLive] = useState(true);

    const handleStaticLiveSwitch = () => {
        setShowLive(!showLive)
    }

    return <>
        {showLive 
        ? <Live
            contentHeight={contentHeight}
            camera={camera}
            handleStaticLiveSwitch={handleStaticLiveSwitch}
        />
        : <Static
            contentHeight={contentHeight}
            camera={camera}
            handleStaticLiveSwitch={handleStaticLiveSwitch}
        />}
    </>
}
import React from 'react';
import StaticLiveSwitch, { ViewType } from './StaticLiveSwitch';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import Title from "../Title"

export default function Live(props) {

    const { contentHeight, camera: { image: imageSource = "", name: cameraName = "", video: videoSource = "" }, handleStaticLiveSwitch } = props
    const { isLandscape } = useMobileOrientation()
    
    const camCount = window.conf.CAMERAS.length

    return (
        <div 
            className="live-view-container"
            style={{
                display: "flex",
                justifyContent: "center",
                position: "relative"
            }}
        >
            <Title cameraName={cameraName} />
            {imageSource && <StaticLiveSwitch type={ViewType.Image} onClick={handleStaticLiveSwitch} />}
            <iframe
                allowFullScreen
                title={cameraName} 
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: isMobileOnly
                            ? isLandscape
                                ? contentHeight
                                : camCount < 2
                                    ? contentHeight
                                    : camCount < 3
                                        ? contentHeight/2
                                        : 'unset'
                            : contentHeight/Math.ceil(camCount/2),
                    width: "99.5%",
                    border: "none"
                }}
                className={
                    !isMobile
                    ? camCount < 2
                        ? ''
                        : camCount === 2
                            ? 'height-2'
                            : 'height'
                    : ''
                }
                src={videoSource} 
            />
        </div>
    )
}
import dataReducer from './dataSlice'
import { graphqlApi } from './graphqlApi'
import { configureStore } from '@reduxjs/toolkit'
import { statsApi } from './statsApi'

export const store = configureStore({
    reducer: {
        data: dataReducer,
        [graphqlApi.reducerPath]: graphqlApi.reducer,
        [statsApi.reducerPath]: statsApi.reducer
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({ serializableCheck: false })
        .concat(graphqlApi.middleware)
        .concat(statsApi.middleware)
})
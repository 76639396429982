import { Typography } from '@mui/material';

export default function Title({ cameraName, loading }) {
    return cameraName && (
        <div className="Title">
            <div className="Title-Label">
                <Typography variant="body1" className={loading ? "Title-CameraLabel-disabled" : "Title-CameraLabel"}>
                    {cameraName}
                </Typography>
            </div>
        </div>
    )
}
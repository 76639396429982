import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useFormControl } from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
  
export default function FormField({ id, variant = "outlined", required, value, ...rest }) {
    return (
        <FormControl
            fullWidth
            variant={variant}
            defaultValue={value}
            required={required}
        >
            <FormChildren
                id={id}
                required={required}
                variant={variant}
                value={value}
                {...rest}
            />
        </FormControl>
    );
};

const FormChildren = ({ id, variant = "outlined", ...rest }) => {

    const formControlContext = useFormControl();

    const { t } = useTranslation();
    const { error, required, filled, focused } = formControlContext;

    const [dirty, setDirty] = useState(false);
    const [helperText, setHelperText] = useState(rest.helperText);
    
    const showRequiredError = dirty && required && !filled && !focused;

    useEffect(() => {
        if (formControlContext?.focused) {
            setDirty(true);
        }
    }, [formControlContext]);

    useEffect(() => {
        if (error) {
            setHelperText(error.message);
        } else if (showRequiredError) {
            setHelperText(t("msg.required"));
        } else {
            setHelperText(rest.helperText);
        }
    }, [error, rest.helperText, showRequiredError, t]);

    return (
        <>
            <InputLabel
                error={error || showRequiredError}
                htmlFor={`${id}Input`}
                variant={variant}
                id={`${id}Label`}
            >
                {rest.label}
            </InputLabel>

            <OutlinedInput
                id={`${id}Input`}
                labelid={`${id}Label`}
                required={required}
                variant={variant}
                error={error || showRequiredError}
                {...rest}
            />

            {helperText && (
                <FormHelperText
                    sx={{ px: 0, py: 0.5, m: 0 }}
                    error={error || showRequiredError}
                    id={`${id}HelperText`}
                >
                    {helperText}
                </FormHelperText>
            )}
        </>
    );
};
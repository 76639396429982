import { useState, Fragment } from "react";
import { IconButton, Menu, MenuItem, Tooltip, Divider, ListItemIcon, Grid, Link, Typography, Stack, Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CopyrightOutlined, Logout, PaletteOutlined, Settings as SettingsIcon } from '@mui/icons-material';
import { useKeycloak } from '../Keycloak';
import { useNavigate } from "react-router-dom";
import NetCoLogo from '../../assets/logo_netco_bau_tv.png';

export default function Settings({ invertColors, toggleInvertColors }) {

    const [anchorEl, setAnchorEl] = useState(null)
    const [openCopyright, setOpenCopyright] = useState(false)
    
    const { t, i18n } = useTranslation()
    const { doLogout, isLoggedIn } = useKeycloak()
    const { HOME } = window.conf.ROUTES

    const navigate = useNavigate()
    const curLang = i18n.language

    const handleOpen = (event) => {
        if (anchorEl !== event?.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleOpenCopyright = () => {
        setOpenCopyright(true);
    }

    const changeLanguage = (ln) => {
        i18n.changeLanguage(ln)
        window.localStorage.setItem('netco_lang', ln);
    }

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    const handleCloseCopyright = () => {
        setOpenCopyright(false)
    }

    const handleLogout = () => {
        navigate(HOME.path)
        handleClose()
        doLogout()
    }

    return (
        <Fragment>
            <Tooltip 
                title={t("user.settings")}
                arrow
                placement="bottom-start"
            >
                <IconButton
                    sx={{ 
                        p: 0,
                        pb: { xs: 0, md: "1px" },
                        pl: { xs: "14px", md: '8px' },
                        color: invertColors ? 'white' : 'primary.main'
                    }}
                    onClick={handleOpen}
                >
                    <SettingsIcon 
                        sx={{ 
                            fontSize: "1.2rem", 
                            pb: "1px"
                        }}
                    />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >

                <MenuItem 
                    onClick={() => changeLanguage('de')} 
                    sx={{ backgroundColor: curLang === 'de' ? '#e0e6ec' : 'white' }}
                    selected={curLang === 'de'}
                >
                    <ListItemIcon>
                        <div className="flag flag_de"></div>
                    </ListItemIcon>
                    {t(`user.language.german`)}
                </MenuItem>

                <MenuItem 
                    onClick={() => changeLanguage('en')} 
                    sx={{ backgroundColor: curLang === 'en' ? '#e0e6ec' : 'white' }}
                    selected={curLang === 'en'}
                >
                    <ListItemIcon>
                        <div className="flag flag_en"></div>
                    </ListItemIcon>
                    {t(`user.language.english`)}
                </MenuItem>

                <MenuItem 
                    onClick={() => changeLanguage('nl')} 
                    sx={{ 
                        backgroundColor: curLang === 'nl' ? '#e0e6ec' : 'white', 
                    }}
                    selected={curLang === 'nl'}
                    divider
                >
                    <ListItemIcon>
                        <div className="flag flag_nl"></div>
                    </ListItemIcon>
                    {t(`user.language.dutch`)}
                </MenuItem>

                <MenuItem 
                    onClick={toggleInvertColors}
                    sx={{ 
                        color: invertColors && 'primary.main' 
                    }}
                >
                    <ListItemIcon>
                        <PaletteOutlined fontSize="small" sx={{ color: invertColors && 'primary.main' }} />
                    </ListItemIcon>
                    {t("color.scheme")}
                </MenuItem>

                <MenuItem onClick={handleOpenCopyright}>
                    <ListItemIcon>
                        <CopyrightOutlined fontSize="small" />
                    </ListItemIcon>
                    {t("copyright.copyright")}
                </MenuItem>

                <Dialog
                    onClose={handleCloseCopyright}
                    open={openCopyright}
                >
                    <Grid
                        container
                        rowSpacing={1.6}
                        direction="column"
                        sx={{
                            textAlign: "center",
                            p: 2,
                            maxWidth: "280px",
                            userSelect: "none"
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Stack
                                spacing={1}
                                direction="column"
                                sx={{
                                    alignItems: "center"
                                }}
                            >
                                <img
                                    style={{ width: 172 }}
                                    src={NetCoLogo}
                                    alt="NetCo Logo"
                                />
                                <Typography sx={{ fontSize: "1rem", pb: 1 }}>
                                    {t("copyright.title")}
                                </Typography>
                            </Stack>
                            <Divider />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{ p: 0, m: 0 }}
                        >
                            <Link href='https://www.kamera.netco.de/datenschutz/' underline="none" target='_blank' onClick={handleCloseCopyright}>
                                <Typography sx={{ fontSize: "1rem", pb: 1 }}>
                                    {t("copyright.privacy")}
                                </Typography>
                            </Link>
                            <Divider />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                        >
                            <Link href='https://www.kamera.netco.de/impressum/' underline="none" target='_blank' onClick={handleCloseCopyright}>
                                <Typography sx={{ fontSize: "1rem", pb: 1 }}>
                                    {t("copyright.print")}
                                </Typography>
                            </Link>
                            <Divider />
                        </Grid>


                        <Grid
                            item
                            xs={12}
                        >
                            <Link href='https://www.baustellen-webcams.de/' underline="none" target='_blank' onClick={handleCloseCopyright}>
                                <Typography sx={{ fontSize: "1rem" }}>
                                    baustellen-webcams.de
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Dialog>

                <MenuItem 
                    onClick={handleLogout} 
                    disabled={!isLoggedIn()} 
                    divider
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t(`user.logout`)}
                </MenuItem>

                <MenuItem 
                    sx={{ pb: 0, justifyContent: "center" }} 
                    disabled
                >
                    <Typography sx={{ fontSize: "0.96rem" }}>
                        {t("user.version")}: {process.env.REACT_APP_VERSION}
                    </Typography>
                </MenuItem>

            </Menu>
        </Fragment>
    )
}
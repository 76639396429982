import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export default function Searchbar({ placeholder, action }) {
    
    const [searchValue, setSearchValue] = useState("")

    const onChangeSearch = event => {
        const value = event.target.value
        setSearchValue(value)
        setDataDebounced(value)
    }

    const setDataDebounced = useDebouncedCallback(value => {
        action(value)
    }, 500)

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            value={searchValue}
            variant="outlined"
            onChange={onChangeSearch}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                )
            }}
            multiline
            rows={1}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
    )
}
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

const { STATS, KEYCLOAK } = window.conf

export const statsApi = createApi({
    reducerPath: 'statsApi',
    baseQuery: fetchBaseQuery({ baseUrl: STATS.SERVER }),
    endpoints: (builder) => ({
        getVisits: builder.query({
            query: (args) => ({
                url: `report/EATime/data?figures=unique_visits&startDate=${args.startDate}&endDate=${args.endDate}&attributes=_(year)_&attributeFilter=%5B%7B%22attributeId%22%3A%22page_name%22%2C%22input%22%3A%5B%22${KEYCLOAK.CLIENT_ID}%22%5D%2C%22filter%22%3A%22include%22%2C%22type%22%3A%22exact%22%2C%22filterType%22%3A%22extended%22%7D%5D`,
                headers: STATS.HEADERS
            })
        }),
        getVisitsGraphData: builder.query({
            query: (args) => ({
                url: `report/EATimeDay/data?startDate=${args.startDate}&endDate=${args.endDate}&sortColumn=utm_date&sortOrder=2&attributes=utm_date&figures=unique_visits&attributeFilter=%5B%7B%22attributeId%22%3A%22page_name%22%2C%22input%22%3A%5B%22${KEYCLOAK.CLIENT_ID}%22%5D%2C%22filter%22%3A%22include%22%2C%22type%22%3A%22exact%22%2C%22filterType%22%3A%22extended%22%7D%5D`,
                headers: STATS.HEADERS
            })
        }),
        getLocations: builder.query({
            query: (args) => ({
                url: `report/EAGeo/data?startDate=${args.startDate}&endDate=${args.endDate}&attributes=geo_country&sortColumn=unique_visits&sortOrder=1&limit=101&attributeFilter=%5B%7B%22attributeId%22%3A%22page_name%22%2C%22input%22%3A%5B%22${KEYCLOAK.CLIENT_ID}%22%5D%2C%22filter%22%3A%22include%22%2C%22type%22%3A%22exact%22%2C%22filterType%22%3A%22extended%22%7D%5D`,
                headers: STATS.HEADERS
            })
        }),
        getDevices: builder.query({
            query: (args) => ({
                url: `report/EADeviceType/data?startDate=${args.startDate}&endDate=${args.endDate}&displayType=grouped&sortColumn=unique_visits&sortOrder=1&limit=101&attributes=device_type&attributeFilter=%5B%7B%22attributeId%22%3A%22page_name%22%2C%22input%22%3A%5B%22${KEYCLOAK.CLIENT_ID}%22%5D%2C%22filter%22%3A%22include%22%2C%22type%22%3A%22exact%22%2C%22filterType%22%3A%22extended%22%7D%5D`,
                headers: STATS.HEADERS
            })
        })
    })
})

export const {
    useGetVisitsQuery,
    useGetVisitsGraphDataQuery,
    useGetLocationsQuery,
    useGetDevicesQuery
} = statsApi
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { isMobile } from 'react-device-detect';

export default forwardRef(
    function Image(props, ref) {

        const { t } = useTranslation()
        const { source, cursor, refresh, fallbackSource } = props
        const { breakpoints } = useTheme();

        const [src, setSrc] = useState(source)
        const [refreshCount, setRefreshCount] = useState(0)
        
        const moreThanOneCamera = window.conf.CAMERAS.length > 1
        const isMd = useMediaQuery(breakpoints.down('md'))

        useEffect(() => {
            if (refresh) {
                var id = setInterval(() => {
                    setRefreshCount(refreshCount+1);
                    setSrc(null)
                    setSrc(`${source}?interval=${refreshCount}`)
                }, refresh)
            
                return () => clearInterval(id)
            }
        }, [refreshCount]) // eslint-disable-line react-hooks/exhaustive-deps

        useEffect(() => {
            if (!refresh && src !== source) {
                setSrc(source)
            }
        }, [source]) // eslint-disable-line react-hooks/exhaustive-deps
        
        function onErrorImgSrc() {
            if (src !== fallbackSource) {
                setSrc(fallbackSource)
            }
        }

        return (
            <div style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: moreThanOneCamera && !isMobile && !isMd ? "0.2rem" : "0",
                boxSizing: "border-box"
            }}>
                <img 
                    // doesnt work: onDoubleClick={() => fullscreenHandle.enter}
                    // onClick={() => console.log("clicked")}
                    ref={ref}
                    src={src}
                    alt={t("image.construction_site")}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        cursor: cursor,
                    }}
                    onError={onErrorImgSrc}
                />
            </div>
        )
}
)
import { ArrowCircleUp, ArrowCircleDown } from '@mui/icons-material';
import { Box, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useEffect, useState, useCallback, useRef } from 'react';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getData, setCheckedArchivePictures } from '../../api/dataSlice';
import { useLazyGetTagsQuery } from '../../api/graphqlApi';
import { approachNumber, useIsOverflow } from '../Utilities';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import OverviewImage from './OverviewImage';

const OverviewGrid = ({ handleOverviewClick, showOverview, imgHeight, cameraId, cameraUrl, imagesList, imageIndex, contentHeight, fullscreenHandle, onImageSelected, fallbackSource }) => {
    
    const { t } = useTranslation();
    const { isLandscape } = useMobileOrientation();
    const { overViewCameraId } = useSelector(getData);
    const { CAMERAS } = window.conf;

    const [scrollImages, setScrollImages] = useState([]);
    const [hasMoreValue, setHasMoreValue] = useState(true);
    const [imageDate, setImageDate] = useState(null);
    const [todayImages, setTodayImages] = useState([]);
    const [isAtScrollStart, setIsAtScrollStart] = useState(false);
    const [getTags] = useLazyGetTagsQuery();
    const [taggedArchivePictureIds, setTaggedArchivePictureIds] = useState([])

    const dispatch = useDispatch();
    const numImagesToLoadAtScroll = 20;
    const comparisonDateFormat = "YYYY-MM-DD";
    const databaseDateFormat = 'YYYY-MM-DDTHH:mm:00.000[Z]'
    const cameraName = CAMERAS[cameraId].name
    const camCount = CAMERAS.length
    const ref = useRef()
    const isOverflow = useIsOverflow(ref)
    
    // add resize event listener to close overview when browser size changes
    useEffect(() => {
        window.addEventListener('resize', closeOverview)
        return () => window.removeEventListener('resize', closeOverview)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    function closeOverview () {
        if (showOverview) {
            handleOverviewClick()
        }
    }
    
    // Track changes on current image index and date
    useEffect(() => {
        const timestamp = dayjs(imagesList?.[0]?.[imageIndex]?.[0])
        const timestampDatabase = timestamp.format(databaseDateFormat)
        const timestampComparison = timestampDatabase.slice(0,10) // databaseDateFormat to comparisonDateFormat

        setImageDate(timestampComparison)
        getTaggedArchivePictureIds(timestampComparison)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesList, imageIndex]);
    
    const getTaggedArchivePictureIds = useCallback(async(date) => {
        try {
            let tags = await getTags({ date: date, index: cameraId })
            let tagList = tags.data.map(tag => tag.archivePicturesId)
            let tagListWithoutDuplicates = [...new Set(tagList)]

            setTaggedArchivePictureIds(tagListWithoutDuplicates)
        } catch (error) {
            console.error(error)
        }
    }, [cameraId, getTags])
    
    // If another overview gets open then close this overview component
    useEffect(() => {
        if (overViewCameraId !== cameraId) {
            onImageSelected(null, true); // do not change the index and only hide the overview
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overViewCameraId])

    // If user changes the date then empty scrollView and load new images in today images array
    useEffect(() => {
        if (imageDate != null) {
            setScrollImages([]);
            setHasMoreValue(true);
            const selectedImageDate = dayjs(imageDate);
            let imagesOfDate = imagesList[1][selectedImageDate.$y][selectedImageDate.$M][selectedImageDate.$D];
            let imagesWithIndex = [];
            let imageHours = Object.keys(imagesOfDate);
            for (let h = 0; h < imageHours.length; h++) {
                const minutes = Object.keys(imagesOfDate[imageHours[h]]);
                for (let m = 0; m < minutes.length; m++) {
                    const image = imagesOfDate[imageHours[h]][minutes[m]];
                    const hourKey = Object.keys(image)[0];
                    const imageIndex = image[hourKey];
                    imagesWithIndex.push({ image: imagesList[0][imageIndex], index: imageIndex });
                }
            }
            setTodayImages(imagesWithIndex);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageDate]);

    // Load images into scrollview if today's images list changes
    useEffect(() => {
        if (todayImages.length > 0) {
            todayImages.length <= numImagesToLoadAtScroll ? setHasMoreValue(false) : setHasMoreValue(true);
            loadScrollViewData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todayImages])

    //Load more images if user has scrolled the visibile images
    const handleOnRowsScrollEnd = () => {
        if (scrollImages.length < todayImages.length) {
            setHasMoreValue(true);
            loadScrollViewData();
        } else {
            setHasMoreValue(false);
        }
    };

    // Get a slice of data from all images list
    const loadScrollViewData = () => {
        const startIndex = scrollImages.length;
        const endIndex = (startIndex + numImagesToLoadAtScroll) < todayImages.length ? startIndex + numImagesToLoadAtScroll : todayImages.length;
        const imagesChunk = todayImages.slice(startIndex, endIndex);
        setScrollImages([...scrollImages, ...imagesChunk]);
    };


    const hasPreviousDayImages = () => {
        if (imageDate) {
            const prevDate = dayjs(imageDate).subtract(1, 'day');
            const lastDayImages = imagesList[1][prevDate.$y][prevDate.$M][prevDate.$D];
            return lastDayImages !== undefined;
        } else {
            return false
        }
    }
    
    function onScrollChangeListener(event) {
        let scrollDiv = document.getElementById("scrollableDiv");
        let scrollTop = scrollDiv?.scrollTop;

        // check if user has scrolled to the start of scrollview container
        if (scrollTop > -30) {
            setIsAtScrollStart(true)
        } else {
            setIsAtScrollStart(false)
        }
    }

    function changeIndexByTimestamp(timestamp) {
        if (imagesList && timestamp) {
            let dict = imagesList[1];
            let indexes = [timestamp.$y, timestamp.$M, timestamp.$D, timestamp.$H, timestamp.$m] // split timestamp in parts
            // iterate through dictionary
            for (let i = 0; i < indexes.length; i++) {
                const elem = indexes[i]
                if (dict[elem]) {
                    dict = dict[elem]
                }
            }
            let newIndex = dict[timestamp.$m];
            // check if minute of timestamp is an available key of the dictionary, otherwise search for prev/next minute in keys
            if (Number.isFinite(newIndex)) {
                newIndex = dict[timestamp.$m]
            } else {
                var minutes = Object.keys(dict)
                var goal = timestamp.$m
                var closest = approachNumber(minutes, goal)
                newIndex = dict[closest][closest]
            }
            if (Number.isFinite(newIndex)) {
                // select new image index and do not hide the overview
                onImageSelected(newIndex, false);
            }
        }
    }

    const renderOverviewImage = (image, index) => {
        let timestamp = image[0]
        let archivePicture = image[1][cameraId]
        let isSelected = imageIndex === index
        let isTagged = Boolean(taggedArchivePictureIds.find(id => id === archivePicture.id)) // check if img have index (id) equal to one of the tagged img ids 

        if (!!archivePicture) {
            return (
                <OverviewImage
                    key={index}
                    onImageSelected={onImageSelected}
                    timestamp={timestamp}
                    index={index}
                    cameraUrl={cameraUrl}
                    isSelected={isSelected}
                    isTagged={isTagged}
                    archivePicture={archivePicture}
                    imageIndex={imageIndex}
                    fallbackSource={fallbackSource}
                />
            )
        }
    }

    const renderFullScreenDateSwitch = () => {
        return (
            <>
                {hasPreviousDayImages() && 
                <IconButton
                    style={{
                        position: 'absolute',
                        top: cameraName ? '1.85rem' : 0,
                        right: isOverflow ? 8 : 0,
                    }}
                    onClick={() => {
                        // Decrement date by 1 day
                        let newDate = dayjs(imageDate).subtract(1, 'day').format(comparisonDateFormat);
                        let timestamp = dayjs(imagesList[0][imageIndex][0]).set('date', dayjs(newDate).$D).set('month', dayjs(newDate).$M);
                        changeIndexByTimestamp(timestamp);
                        dispatch(setCheckedArchivePictures({ type: 'clean' }))
                    }}
                >
                    <Tooltip title={t("date.previous_day")} arrow>
                        <ArrowCircleUp style={styles.dateSwitchIcon} />
                    </Tooltip>
                </IconButton>}

                {imageDate !== dayjs().format(comparisonDateFormat) && 
                isAtScrollStart && 
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '1.85rem',
                        right: isOverflow ? 8 : 0,
                    }}
                    onClick={() => {
                        // Incremet date by 1 day
                        let newDate = dayjs(imageDate).add(1, 'day').format(comparisonDateFormat);
                        let timestamp = dayjs(imagesList[0][imageIndex][0]).set('date', dayjs(newDate).$D).set('month', dayjs(newDate).$M);
                        changeIndexByTimestamp(timestamp);
                        dispatch(setCheckedArchivePictures({ type: 'clean' }))
                    }}
                >
                    <Tooltip placement='top' title={t("date.next_day")} arrow>
                        <ArrowCircleDown style={styles.dateSwitchIcon} />
                    </Tooltip>
                </IconButton>}
            </>
        );
    }

    const subtractHeight = cameraName ? 69 : 36

    const styles = {
        containerStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "33px",
            marginTop: cameraName ? "36px" : "3px",
            height: fullscreenHandle.active
                ? "100%"
                : isMobileOnly
                    ? isLandscape
                        ? imgHeight - subtractHeight
                        : camCount < 2
                            ? contentHeight - subtractHeight
                            : camCount < 3
                                ? (contentHeight / 2) - subtractHeight
                                : imgHeight - subtractHeight
                    : imgHeight - subtractHeight
        },
        scrollContainer: {
            height: '100%',
            overflow: 'auto',
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
        },
        scrollBar: {
            overflow: "unset",
            display: 'flex',
            flexDirection: 'column'
        },
        dateSwitchIcon: {
            background: 'white',
            color: '#003366',
            fontSize: 48,
            borderRadius: 22
        }
    }

    return (
        <div 
            style={styles.containerStyle}
            ref={ref}
        >
            <div
                id="scrollableDiv"
                style={{...styles.scrollContainer}}
                onScroll={onScrollChangeListener}
            >
                <InfiniteScroll
                    dataLength={scrollImages.length}
                    next={handleOnRowsScrollEnd}
                    hasMore={hasMoreValue}
                    scrollThreshold={0.8}
                    scrollableTarget="scrollableDiv"
                    style={styles.scrollBar}
                    loader={<LinearProgress />}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid
                            container
                            spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            direction="row"
                        >
                            {/* render image items from the scroll data */}
                            {scrollImages.map((camImage) => renderOverviewImage(camImage.image, camImage.index))}
                        </Grid>
                    </Box>
                </InfiniteScroll>
            </div>
            {renderFullScreenDateSwitch()}
        </div>
    );
}

export default OverviewGrid;
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Global } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { Download as DownloadIcon, DateRange } from '@mui/icons-material';
import { Grid, Container, Fade, IconButton, SwipeableDrawer, Box, Stack, Toolbar, Drawer, useTheme } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux"
import { setSource, getData, setIndex, loadNextImage, loadPrevImage, setTimestamp, setEvents, setInitialFooterHeight } from "../../api/dataSlice"
import { LocalizationProvider, TimePicker } from '@mui/lab';
import { normalizeWeatherCode, useWindowDimensions } from "../Utilities"
import { useGetDataByCameraQuery, useLazyGetTagsByCameraQuery } from "../../api/graphqlApi";
import { useTranslation } from 'react-i18next';
import Download from './Download'
import Calendar from './Calendar' 
import Events from './Events'
import TimeSelector from './TimeSelector'
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import i18next from 'i18next';

export default function Controls({ initialSiderWidth, onOpenSideDrawer, setPaddingFooter, drawerBleeding }) {
    
    const { CAMERAS, WEATHER_CATEGORY, DEBUG } = window.conf
    const { t } = useTranslation()
    const { source, index, timestamp, events, initialFooterHeight } = useSelector(getData);
    const { isPortrait } = useMobileOrientation()
    const { data, isLoading: loading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 })
    const { contentHeight, appbarHeight } = useWindowDimensions()
    
    const [getTagsByCamera] = useLazyGetTagsByCameraQuery()
    const [canStepLeft, setCanStepLeft] = useState(false)
    const [canStepRight, setCanStepRight] = useState(false)
    const [footerOpen, setFooterOpen] = useState(false)
    const [footerHeight, setFooterHeight] = useState(48)
    const [footerContent, setFooterContent] = useState(1) // 0 - Download, 1 - Calendar
    const [siderContent, setSiderContent] = useState(1) // 0 - Download, 1 - Calendar
    const [firstRun, setFirstRun] = useState(true)
    const [pickerOpen, setPickerOpen] = useState(false)
    const [loadingEvents, setLoadingEvents] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [openTagModal, setOpenTagModal] = useState(false)
    const [tagData, setTagData] = useState()
    const [isError, setIsError] = useState(false)
    
    const theme = useTheme()
    const refSider = useRef()
    const dispatch = useDispatch()

    const onOpenTagModal = (row) => {
        setOpenTagModal(true)
        setTagData(row)

        // track viewing form
        // if (etForm && typeof(etForm) === "object") { // eslint-disable-line no-undef
        //     etForm.sendEvent('formView', 'Ereignisformular'); // eslint-disable-line no-undef
        // }
    }

    const onCloseTagModal = () => {
        setOpenTagModal(false)
        setTagData(null)
    }

    const toggleDrawer = (isOpen) => () => {
        setIsDrawerOpen(isOpen);
        onOpenSideDrawer(isOpen);
        if (isOpen) document.body.style.overflow = 'auto'
    };

    const updateTimestamp = (timestamp) =>{
        dispatch(setTimestamp(timestamp));
    }

    const footerHeightCalendar = footerOpen
        ? events?.length
            ? footerHeight + 170 + events.length * 33 > contentHeight // higher than 80% of window height
                ? contentHeight - 20 // limit to content height
                : footerHeight + 170 + (events.length * 33) // depending on footer elements and data entrys
            : footerHeight + 170 + 22 // depending on footer elements (picker, divider, searchbar, no-data display)
        : footerHeight

    const footerHeightDownload = footerOpen
        ? CAMERAS.length < 2 
            ? isError 
                ? footerHeight + 278
                : footerHeight + 215
            : isError
                ? footerHeight + 351
                : footerHeight + 287
        : footerHeight

    const closeFooter = useCallback(() => {
        if (footerOpen) {
            setFooterHeight(initialFooterHeight)
            setFooterOpen(false)
        }
    }, [footerOpen, initialFooterHeight])


    // read tags from database - needed to get consistent event list after CRUD operations by AddTag
    const readTags = useCallback(async() => {
        if (source && index) {
            try {
                var archivePictureByCamera = source[0]?.[index]?.[1]
                var archivePicturesDetailsByCamera = await getTagsByCamera({ archivePictureByCamera: archivePictureByCamera })
                var tags = 
                    archivePicturesDetailsByCamera?.data?.map((details, index) => 
                        details?.map(elem => {
                            let isWeather = elem.kategorie === WEATHER_CATEGORY
                            let category = isWeather ? t(`weather.weather`) : elem.kategorie
                            let description = isWeather ? t(`weather.conditions.${normalizeWeatherCode(elem.beschreibung)}`) : elem.beschreibung
                            let weather = elem.weatherArchives?.[0]

                            if (isWeather && !!weather) {
                                description = `${Math.round(weather.temp)}°, ${t(`weather.conditions.${normalizeWeatherCode(weather.weatherCode)}`)}, ${t(`weather.wind`)} ${weather.windSpeed} km/h, ${t(`weather.humidity`)} ${weather.humidity}%`
                            }

                            return ({ 
                                id: elem.id,
                                camera: CAMERAS[index], 
                                category: category,
                                description: description,
                                archivePicturesId: elem.archivePicturesId
                            })
                        })
                    ).flat().filter(elem => elem) // filter empty tags

                return tags ?? []

            } catch (error) {
                console.error(error)
            }
        }
    }, [index, source, getTagsByCamera]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadEvents = useCallback(async() => {

        setLoadingEvents(true)

        var tags = await readTags()

        if (tags) {

            var events = [...tags]
            var eventsHeight = (footerOpen ? 20 : 48 + (events.length <= 3 ? events.length : 3) * 29)
    
            if (DEBUG) {
                console.log("events", events)
            }
    
            dispatch(setEvents(events))
            dispatch(setInitialFooterHeight(eventsHeight))
            setFooterHeight(eventsHeight)
        } 
        
        setLoadingEvents(false)
    }, [readTags, dispatch, footerOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadEvents()
    }, [loadEvents])

    useEffect(() => {
        if (index) {
            updateTimestamp(source?.[0]?.[index]?.[0])
        }
    }, [index]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { 
        if (data) {
             if (firstRun) {
                var list = data[0]
                var lastIndex = list.length-1
                var lastElement = list[lastIndex]

                // set time in TimeSelector for display clock by date of last image (ONLY ONCE!)
                updateTimestamp(lastElement?.[0])
    
                // set index in redux store for Controls
                dispatch(setIndex(lastIndex))

                setFirstRun(false)
            }

            // set source in redux store for Content
            dispatch(setSource(data))

            if (DEBUG) {
                console.log("source", data)
            }
        }
    }, [data, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    // enable/disable stepping left/right executed by TimeSelector
    useEffect(() => {
        if (source && Number.isFinite(index)) {
            var list = source[0]
            var incrementedIndex = index - 1
            var decrementedIndex = index + 1

            if (list?.[incrementedIndex]) {
                setCanStepLeft(true)
            } else {
                setCanStepLeft(false)
            }

            if (list?.[decrementedIndex]) {
                setCanStepRight(true)
            } else {
                setCanStepRight(false)
            }
        }
    }, [source, index])
    
    useEffect(() => {
        setPaddingFooter(footerHeightDownload)
    }, [footerHeightDownload, setPaddingFooter])

    function openFooter(component) {
        if (!footerOpen) {
            setFooterContent(component)
            setFooterOpen(true)
            setSiderContent(component)
            setFooterHeight(20)
        }
    }

    function stepLeft() {
        if (Number.isFinite(index) && canStepLeft) {
            dispatch(loadPrevImage());
        } 
    }

    function stepRight() {
        if (Number.isFinite(index) && canStepRight) {
            dispatch(loadNextImage());
        } 
    }

    function shouldDisableDate(date) {
        var dict = source?.[1]
        var hasDay = dict?.[date.$y]?.[date.$M]?.[date.$D]
        return hasDay ? false : true
    }

    function shouldDisableTime(time, type) {
        var dict = source?.[1]
        switch (type) {
            case "hours":
                var hasHour = dict?.[timestamp?.$y]?.[timestamp?.$M]?.[timestamp?.$D]?.[time]
                return hasHour ? false : true
            case "minutes":
                var hasMinute = dict?.[timestamp?.$y]?.[timestamp?.$M]?.[timestamp?.$D]?.[timestamp?.$H]?.[time]
                return hasMinute ? false : true
            default:
                return false
        }
    }

    useEffect(() => {
        if (footerOpen) {
            setFooterHeight(20)
        } else {
            setFooterHeight(48)
        }
    }, [footerOpen])

    useEffect(() => {
        return () => {
            onOpenSideDrawer(false)
            setIsDrawerOpen(false);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps  
    
    // mobile footer portrait
    if (isMobile && isPortrait) {
        return (
           <Container sx={{ backgroundColor: "black", height: "100%" }}>
                
                {/* https://emotion.sh/docs/globals */}
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: footerContent
                                ? footerHeightCalendar
                                : footerHeightDownload,
                            overflow: 'visible'
                        }
                    }}
                />

                <SwipeableDrawer
                    anchor="bottom"
                    open={footerOpen}
                    onClose={closeFooter}
                    onOpen={openFooter}
                    swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                    disableSwipeToOpen={true} // prevent opening by swiping because its opened by buttons
                    ModalProps={{ keepMounted: true }}
                    sx={{ transform: footerOpen ? undefined : `translateY(${footerHeight}px)` }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -footerHeight,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            backgroundColor: "white",
                            height: `${footerHeight}px`
                        }}
                    >
                        <Grid 
                            container 
                            columns={{ xs: 1 }} 
                            sx={{ height: footerOpen ? "inherit" : "unset" }}
                        >
                            <Grid 
                                item 
                                xs={1} 
                                sx={{ 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    height: footerOpen ? "inherit" : "unset" 
                                }}
                            >
                                <Fade in={footerOpen}>
                                    <Box 
                                        sx={{
                                            width: 30,
                                            height: 6,
                                            backgroundColor: grey[300],
                                            borderRadius: 3,
                                            position: 'absolute',
                                            top: 8,
                                            left: 'calc(50% - 15px)'
                                        }}
                                    />
                                </Fade>

                                <Box sx={{ flexGrow: 1 }}>                        
                                    <Fade in={!footerOpen}>
                                        <IconButton 
                                            sx={{ flexGrow: 1, p: 1, pointerEvents: "all" }}
                                            aria-label="download"
                                            onClick={() => openFooter(0)}
                                            color="primary"
                                            disabled={loading}
                                        >
                                            <DownloadIcon
                                                sx={{ fontSize: "2rem" }} 
                                            />
                                        </IconButton>
                                    </Fade>
                                </Box>
                                
                                <TimeSelector 
                                    footerOpen={footerOpen} 
                                    stepLeft={stepLeft}
                                    timestamp={timestamp} 
                                    stepRight={stepRight} 
                                    date={1} 
                                    canStepLeft={canStepLeft}
                                    canStepRight={canStepRight}
                                    loading={loading}
                                />

                                <Box>
                                    <Fade in={!footerOpen}>
                                        <IconButton
                                            sx={{ p: 1, pointerEvents: "all" }}
                                            aria-label="calendar" 
                                            onClick={() => openFooter(1)}
                                            color="primary"
                                            disabled={loading}
                                        >
                                            <DateRange 
                                                sx={{ fontSize: "2rem" }} 
                                            />
                                        </IconButton>
                                    </Fade>
                                </Box>
                            </Grid>

                            <Grid 
                                item 
                                xs={1} 
                                zeroMinWidth 
                                sx={{ height: footerOpen ? "inherit" : "unset" }}
                            >
                                <Events 
                                    events={events}
                                    onOpenTagModal={onOpenTagModal} 
                                    footerOpen={footerOpen}
                                    preview={true}
                                    loading={loading || loadingEvents}
                                />
                            </Grid>
                            
                        </Grid>
                    </Box>

                    <Box sx={{ p: 2 }}>
                        <Fade in={footerOpen}>
                            <Stack spacing={2}>
                                {footerContent ? (
                                    <Calendar 
                                        timestamp={timestamp} 
                                        setTimestamp={updateTimestamp} 
                                        events={events} 
                                        footerHeightCalendar={footerHeightCalendar}
                                        shouldDisableDate={shouldDisableDate}
                                        loading={loading}
                                        loadingEvents={loadingEvents}
                                        shouldDisableTime={shouldDisableTime}
                                        openTagModal={openTagModal}
                                        onOpenTagModal={onOpenTagModal}
                                        onCloseTagModal={onCloseTagModal}
                                        tagData={tagData}
                                    />
                                ) : (
                                    <Download 
                                        timestamp={timestamp}
                                        setError={setIsError} 
                                        closeDownload={closeFooter}
                                        shouldDisableDate={shouldDisableDate}
                                        loading={loading}
                                    />
                                )}
                            </Stack>
                        </Fade>
                    </Box>

                </SwipeableDrawer>
            </Container>
        )
        
    // mobile sider landscape
    } else if (isMobile && !isPortrait) {
        return (
            <>
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `100%`,
                            overflow: 'visible',
                        },
                    }}
                />

                <SwipeableDrawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                    disableSwipeToOpen={false}
                    hideBackdrop={true} // prevent the background from graying out outside the sider
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        height:'100%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: initialSiderWidth - drawerBleeding,
                            boxSizing: 'border-box'
                        },
                    }}
                >
                    <Box
                        ref={refSider}
                        sx={{
                            backgroundColor: "white",
                            position: 'absolute',
                            height: '100%',
                            top: 0,
                            right: 0,
                            left: -drawerBleeding,
                            visibility: 'visible',
                            overflow: 'auto',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            overflowX: "hidden"
                        }}
                    >
                        
                        {/* puller component to pull out the drawer */}

                        <Box 
                            sx={{
                                position: 'absolute',
                                backgroundColor: isDrawerOpen ? '#e0e0e0' : '#003366',
                                height: 30,
                                width: 6,
                                top: `calc(50% + 8px)`,
                                left: 8,
                                borderRadius: 3
                            }} 
                        />

                        {/* content of the drawer below puller component */}
                        
                        <Box
                            ref={refSider}
                            sx={{
                                overflow: 'auto',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: `${appbarHeight}px`,
                                ml: `${drawerBleeding}px`,
                                height: "100%",
                                maxHeight: `calc(100vh - ${appbarHeight}px)`,
                                p: siderContent === 0 && 2,
                                pt: 2,
                                borderLeft: `1px solid ${theme.palette.divider}`
                            }}
                        >
                            {siderContent ? ( // 0 - Download, 1 - Calendar
                                <>
                                    <LocalizationProvider
                                        dateAdapter={DayjsAdapter}
                                        locale={i18next.language}
                                    >
                                        <TimePicker
                                            open={pickerOpen}
                                            onOpen={() => setPickerOpen(true)}
                                            onClose={() => setPickerOpen(false)}
                                            value={timestamp}
                                            onChange={updateTimestamp}
                                            renderInput={({ inputRef }) =>
                                                <TimeSelector
                                                    stepLeft={stepLeft}
                                                    timestamp={timestamp}
                                                    stepRight={stepRight}
                                                    loading={loading}
                                                    canStepLeft={canStepLeft}
                                                    canStepRight={canStepRight}
                                                    setPickerOpen={setPickerOpen}
                                                    inputRef={inputRef}
                                                />
                                            }
                                            cancelText={t("actions.cancel")}
                                            shouldDisableTime={shouldDisableTime}
                                            showToolbar={false}
                                        />
                                    </LocalizationProvider>

                                    <Calendar
                                        timestamp={timestamp}
                                        setTimestamp={updateTimestamp}
                                        events={events}
                                        footerHeightCalendar={footerHeightCalendar}
                                        initialSiderWidth={initialSiderWidth}
                                        openDownload={() => setSiderContent(0)}
                                        refSider={refSider}
                                        shouldDisableDate={shouldDisableDate}
                                        loading={loading}
                                        loadingEvents={loadingEvents}
                                        openTagModal={openTagModal}
                                        onOpenTagModal={onOpenTagModal}
                                        onCloseTagModal={onCloseTagModal}
                                        tagData={tagData}
                                    />
                                </>
                            ) : (
                                <Download
                                    timestamp={timestamp}
                                    closeDownload={() => setSiderContent(1)}
                                    initialSiderWidth={initialSiderWidth}
                                    refSider={refSider}
                                    shouldDisableDate={shouldDisableDate}
                                    loading={loading}
                                />
                            )}
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </>
        )
        
    // desktop sider
    } else {
        return (
            <Drawer
                variant="permanent"
                anchor="right"
                sx={{
                    width: initialSiderWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { 
                        width: initialSiderWidth, 
                        boxSizing: 'border-box'
                    }
                }}
            >
                <Toolbar /> {/* provides top distance without additional functionality (seems only to work for desktop!) */}

                <Box 
                    ref={refSider}
                    sx={{ 
                        overflow: 'auto',
                        display: "flex",
                        flexDirection: "column", 
                        alignItems: "center",
                        overflowX: "hidden",
                        p: siderContent === 0 ? 2 : 0,
                        pt: 2
                    }}
                >
                    {siderContent ? ( // 0 - Download, 1 - Calendar
                        <>
                            <LocalizationProvider 
                                dateAdapter={DayjsAdapter}
                                locale={i18next.language}
                            >
                                <TimePicker
                                    ampm={false}
                                    open={pickerOpen}
                                    onOpen={() => setPickerOpen(true)}
                                    onClose={() => setPickerOpen(false)}
                                    value={timestamp}
                                    onChange={updateTimestamp}
                                    renderInput={({ inputRef }) => 
                                        <TimeSelector 
                                            stepLeft={stepLeft} 
                                            timestamp={timestamp}
                                            stepRight={stepRight}
                                            loading={loading}
                                            canStepLeft={canStepLeft}
                                            canStepRight={canStepRight}
                                            setPickerOpen={setPickerOpen}
                                            inputRef={inputRef}
                                        />
                                    }
                                    cancelText={t("actions.cancel")}
                                    shouldDisableTime={shouldDisableTime}
                                    showToolbar={false}
                                />
                            </LocalizationProvider>

                            <Calendar 
                                timestamp={timestamp} 
                                setTimestamp={updateTimestamp} 
                                events={events} 
                                footerHeightCalendar={footerHeightCalendar} 
                                initialSiderWidth={initialSiderWidth} 
                                openDownload={() => setSiderContent(0)}
                                refSider={refSider}
                                shouldDisableDate={shouldDisableDate}
                                loading={loading}
                                loadingEvents={loadingEvents}
                                openTagModal={openTagModal}
                                onOpenTagModal={onOpenTagModal}
                                onCloseTagModal={onCloseTagModal}
                                tagData={tagData}
                            />
                        </>
                    ) : (
                        <Download 
                            timestamp={timestamp} 
                            closeDownload={() => setSiderContent(1)} 
                            initialSiderWidth={initialSiderWidth} 
                            refSider={refSider} 
                            shouldDisableDate={shouldDisableDate}
                            loading={loading}
                        />
                    )}
                    
                </Box>

            </Drawer>
        )
    }
}
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getData } from "../../api/dataSlice";
import { useAddTimelapseJobMutation, useCancelTimelapseGenerationMutation, useGetDataByCameraQuery } from "../../api/graphqlApi";
import { Alert, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, LinearProgress, MenuItem, Slider, TextField, Typography, styled, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from "@mui/material";
import { Close, ArrowForwardIosSharp, Queue, Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDebouncedCallback } from "use-debounce";
import { useSubscription } from "@apollo/client";
import { timelapseJobStatusUpdatedByUuid } from "../../api/graphqlSubscription";
import { saveFile } from "../Utilities";
import { useKeycloak } from "../Keycloak";
import { isMobile, useMobileOrientation } from 'react-device-detect';
import DateRangePicker from "./DateRangePicker";

const PrimarySliderCustom = styled(Slider)({
    color: "#0096fb",
    '& .MuiSlider-markLabel[data-index="0"]': {
        display: "none"
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        display: "none"
    },
});

const Accordion = styled(({ expanded, isPortrait, ...props }) => (
    <MuiAccordion
        disableGutters
        square
        elevation={0}
        expanded={expanded} 
        slotprops={{ transition: { unmountOnExit: true } }} // improve performance (see https://mui.com/material-ui/react-accordion/#performance)
        {...props}
    />
))(({ theme, expanded, isPortrait }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: isMobile && !isPortrait && `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:last-child': {
        borderBottom: expanded && 0
    },
    '&::before': {
        display: 'none'
    }
}));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#F7F7F7',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        ...theme.typography.h6,
        marginLeft: theme.spacing(1),
        textTransform: 'uppercase',
        fontSize: { xs: '1rem', sm: '1.25rem' },
        fontWeight: undefined
    }
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
}));

const Progress = (props) => (
    <Box
        sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            overflow: "hidden",
        }}
    >
        <LinearProgress variant="determinate" value={props.value} />
    </Box>
)

export default function Form({ loading }) {
    
    const defaultJobStatus = {
        uUID: "",
        timelapseUrl: "",
        notifyUser: false,
        jobParameter: {
            state: "",
            delay: 0,
            dateCreated: "",
            userName: "",
            userMail: "",
            language: "",
        }
    };
    
    const defaultErrorMessages = {
        camera: null,
        timespanStart: null,
        timespanEnd: null,
        maxLength: null,
    };

    const defaultDelay = {
        total: 0,
        current: 0,
    };

    const cameras = window.conf.CAMERAS
    const moreThanOneCam = cameras.length > 1
    const accordionOffset = 32; // 16px padding on each side

    const [siderWidth, setSiderWidth] = useState();
    const [jobStatus, setJobStatus] = useState(defaultJobStatus);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadingTotal, setIsDownloadingTotal] = useState(false);
    const [delay, setDelay] = useState(defaultDelay);
    const [isCanceling, setIsCanceling] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadProgressTotal, setDownloadProgressTotal] = useState(0);
    const [quality, setQuality] = useState({ original: { width: 1920, height: 1080 }, reduced: { width: 1280, height: 720 } });
    const [alertMessage, setAlertMessage] = useState({ type: "error", message: null });
    const [errorMessages, setErrorMessages] = useState(defaultErrorMessages);
    const [isValidation, setIsValidation] = useState(false);
    const [addTimelapseJob, { data: job, isLoading: loadingJob, error }] = useAddTimelapseJobMutation();
    const [cancelTimelapseGeneration, { isLoading: loadingCancel, error: errorCancel }] = useCancelTimelapseGenerationMutation();
    const [expanded, setExpanded] = useState('panel1');

    const { t, i18n } = useTranslation();
    const { isLoading: isCameraLoading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 });
    const { timestamp } = useSelector(getData);
    const { getUsername, getEmail } = useKeycloak()
    const { data: dataJobStatus, error: jobStatusError } = useSubscription(timelapseJobStatusUpdatedByUuid, { variables: { uUID: jobStatus?.uUID }, skip: !jobStatus?.uUID });
    const { LAYOUT: { TITLE } } = window.conf;
    const { isPortrait } = useMobileOrientation();

    const [controlledData, setControlledData] = useState({
        camera: cameras?.[0],
        timespanStart: timestamp,
        timespanEnd: timestamp,
        maxLength: 120, // optional
        width: quality.original.width, // optional
        height: quality.original.height, // optional,
        useRecommendedLength: true,
    });
    
    const jobProgress = (jobStatus.uUID && (jobStatus?.jobParameter.state !== "finished" || jobStatus?.jobParameter.state !== "failed")) || false;
    const disabledForm = isDownloading || loadingCancel || loadingJob || jobProgress || loading;
    const disabledButton = disabledForm || isValidation;
    const refSider = useRef();
    const refDownloadButton = useRef();
    const refXhr = useRef();
    const refXhrTotal = useRef();

    const scrollToRef = (ref) => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const onChangeExpanded = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
   };

    const onChangeSelectedCamera = (event) => {
        const id = event.target.value;
        const camera = cameras?.find((camera) => camera.id === id);
        setControlledData({ ...controlledData, camera });
        setAlertMessage(null);
    };

    const handleDownload = (url) => {
        try {
            setIsDownloading(true)
            setDownloadProgress(0)

            if (!url) return
            url = url.replace(/^\/+|\/+$/g, '') // remove leading and trailing slashes

            const xhr = new XMLHttpRequest()
            refXhr.current = xhr // set created xhr object to ref to be able to cancel later

            xhr.open("GET", url, true)
            xhr.responseType = "blob"

            xhr.onprogress = (event) => {
                const percentComplete = (event.loaded / event.total) * 100;
                setDownloadProgress(percentComplete);
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const title = TITLE ? `${TITLE}_` : ""
                    const camera = controlledData.camera.name
                        ? `${controlledData.camera.name}_` 
                        : moreThanOneCam 
                            ? `${t("events.camera")} ${controlledData.camera.id + 1}_` 
                            : ""
                    let startDate = controlledData.timespanStart.format("YY-MM-DD")
                    const endDate = controlledData.timespanEnd.format("YY-MM-DD")

                    if (startDate !== endDate) {
                        startDate += "_" + endDate
                    }

                    saveFile(xhr.response, `${title}${camera}${startDate}.mp4`)
                    setIsDownloading(false)
                }
            }

            xhr.onerror = (event) => {
                console.error(event)
                setIsDownloading(false)
            }

            xhr.send()
        } catch (error) {
            console.error(error)
            setIsDownloading(false)
            setAlertMessage({ type: "error", message: t("timelapse.error.download_failed") })
        }
    }

    const handleAddTimelapseJob = async() => {
        const timespanStart = controlledData.timespanStart.format("YYYY-MM-DD") + "T00:00:00.000Z";
        const timespanEnd = controlledData.timespanEnd.format("YYYY-MM-DD") + "T23:59:59.999Z";

        const data = {
            featureDetailId: controlledData.camera.featureDetailId,
            timespanStart: timespanStart,
            timespanEnd: timespanEnd,
            height: controlledData.height,
            width: controlledData.width,
            maxLength: controlledData.useRecommendedLength
                ? 0
                : controlledData.maxLength,
            userName: getUsername() || "",
            userMail: getEmail() || "",
            language: i18n.language,
        };
        setAlertMessage({ type: "info", message: t("timelapse.receive_email") });
        await addTimelapseJob(data);
    };

    // TODO: create and save is not handled sperately here
    const handleCancel = async() => {
        const res = await cancelTimelapseGeneration({ uUID: jobStatus.uUID });
        if (res.data) {
            setAlertMessage({ type: "info", message: "" });
            setIsCanceling(true);
            setTimeout(() => handleCanceled(), 10000); // TODO: hier auf job updates gucken
        }
    };

    const handleCanceled = useDebouncedCallback(() => {
        setAlertMessage(null);
        setErrorMessages(defaultErrorMessages);
        setIsValidation(false);
        setJobStatus(defaultJobStatus);
        setDelay(defaultDelay);
        setIsCanceling(false);
        if (refXhr.current) {
            refXhr.current.abort();
        }
    }, 1000);

    const handleDownloadTotal = () => {
        try {
            setIsDownloadingTotal(true)
            setDownloadProgressTotal(0)

            const url = controlledData.camera.timelapse
            if (!url) return

            const xhr = new XMLHttpRequest()
            refXhrTotal.current = xhr // set created xhr object to ref to be able to cancel later

            xhr.open("GET", url, true)
            xhr.responseType = "blob"

            const progressBuffer = []; // array for the moving average
            const maxBufferSize = 5; // how many steps are to be averaged

            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;

                    // add to the buffer and shorten if necessary
                    progressBuffer.push(percentComplete);
                    if (progressBuffer.length > maxBufferSize) {
                        progressBuffer.shift(); // remove oldest entries
                    }

                    // calculate average
                    const averageProgress = progressBuffer.reduce((a, b) => a + b, 0) / progressBuffer.length;

                    setDownloadProgressTotal(averageProgress);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const camera = controlledData.camera.name 
                        ? `${controlledData.camera.name}` 
                        : moreThanOneCam 
                            ? `${t("events.camera")} ${controlledData.camera.id + 1}` 
                            : ""
                    const title = TITLE
                        ? camera
                            ? `${TITLE}_`
                            : TITLE
                        : ""

                    saveFile(xhr.response, `${title}${camera}.mp4`)
                    setIsDownloadingTotal(false)
                }
            }

            xhr.onerror = (event) => {
                console.error(event)
                setIsDownloadingTotal(false)
            }

            xhr.send()
        } catch (error) {
            console.error(error)
            setIsDownloadingTotal(false)
        }
    }

    const handleCancelTotal = () => {
        if (refXhrTotal.current) {
            refXhrTotal.current.abort();
        }
        setIsDownloadingTotal(false);
    };

    const handleChangeMaxLength = (event, value) => {
        setControlledData({
            ...controlledData,
            maxLength: value,
        });
    };

    const calculateProgress = () => {
        const progressJob = ((delay.total - delay.current) * 100) / delay.total || 0;
        const progress = !isDownloading
            ? progressJob / 2 || 0
            : (downloadProgress / 2 || 0) + 50;
        return progress;
    };

    const calculateProgressTotal = () => downloadProgressTotal || 0;

    // scroll to download button if alert message is shown e.g. timelapse generation started
    useEffect(() => {
        if (alertMessage) {
            scrollToRef(refDownloadButton);
        }
    }, [alertMessage]);

    useEffect(() => {
        if (error) {
            const errorStr = error?.message;
            setAlertMessage({
                type: "error",
                message: errorStr.substring(0, errorStr.indexOf(".") + 1),
            });
        }
    }, [error]);

    useEffect(() => {
        if (errorCancel) {
            const errorStr = errorCancel?.message;
            setAlertMessage({
                type: "error",
                message: errorStr.substring(0, errorStr.indexOf(".") + 1),
            });
        }
    }, [errorCancel]);

    // validate data
    useEffect(() => {
        let newErrorMessages = {
            camera: null,
            timespanStart: null,
            timespanEnd: null,
            maxLength: null,
        };

        let isValid = true;

        if (!controlledData.camera) {
            newErrorMessages.camera = t("timelapse.error.camera_required");
            isValid = false;
        }

        if (new Date(controlledData.timespanStart).getTime() > new Date(controlledData.timespanEnd).getTime()) {
            newErrorMessages.timespanStart = t("timelapse.error.timespan_invalid");
            isValid = false;
        }

        if (new Date(controlledData.timespanEnd).getTime() < new Date(controlledData.timespanStart).getTime()) {
            newErrorMessages.timespanEnd = t("timelapse.error.timespan_invalid");
            isValid = false;
        }

        if (controlledData.maxLength < 10 || controlledData.maxLength > 120) {
            newErrorMessages.maxLength = t("timelapse.error.max_length_invalid", { min: 10, max: 120 });
            isValid = false;
        }

        setErrorMessages(newErrorMessages);
        setIsValidation(!isValid);
    }, [controlledData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (job?.uUID) {
            var newJobStatus = {
                ...jobStatus,
                ...job,
            }
            setJobStatus(newJobStatus);
        } else {
            setJobStatus(defaultJobStatus);
            setDelay(defaultDelay);
        }
    }, [job]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.state === "finished") {
            setAlertMessage({ type: "success", message: "" });
            setErrorMessages(defaultErrorMessages);
            setIsValidation(false);
            setJobStatus(defaultJobStatus);
            setDelay(defaultDelay);
            handleDownload(dataJobStatus?.timelapseJobStatusUpdatedByUuid?.timelapseUrl);
        } else if (dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.state === "failed") {
            setErrorMessages(defaultErrorMessages);
            setIsValidation(false);
            setJobStatus(defaultJobStatus);
            setDelay(defaultDelay);
            setAlertMessage({ type: "error", message: t("timelapse.error.job_failed") });
        } else if (dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.state === "cancelled") {
            handleCanceled();
        } else {
            setJobStatus({
                ...jobStatus,
                ...dataJobStatus?.timelapseJobStatusUpdatedByUuid,
            });

            // Option 1 check equality of id
            // jobStatus?.uUID !== dataJobStatus?.timelapseJobStatusUpdatedByUuid?.uUID
            // Option 2 check if delay.total is 0
            if (!delay.total) {
                setDelay({
                    total: dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.delay || 0,
                    current: dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.delay || 0,
                });
            } else {
                setDelay({
                    total: delay.total,
                    current: dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.delay || 0,
                });
            }
        }
    }, [dataJobStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleCanceled();
        setAlertMessage({ type: "error", message: jobStatusError?.message });
    }, [jobStatusError]); // eslint-disable-line react-hooks/exhaustive-deps

    // update original quality
    useEffect(() => {
        const quantity = cameras.find((camera) => camera.id === controlledData.camera.id)?.quality;

        if (quantity) {
            setQuality(quantity);
            setControlledData({
                ...controlledData,
                width: quantity.original.width,
                height: quantity.original.height,
            });
        } else if (controlledData.camera?.image) {
            const img = new Image();
            img.src = controlledData.camera.image;
            img.decode()
            .then(() => {
                setQuality({
                    original: { width: img.width, height: img.height },
                    reduced: { width: Math.round(img.width / 1.5), height: Math.round(img.height / 1.5) }
                });

                // set window.conf
                const oldCameras = window.conf.CAMERAS;
                const newCameras = oldCameras.map((camera) => {
                    if (camera.id === controlledData.camera.id) {
                        return {
                            ...camera,
                            quality: {
                                original: { width: img.width, height: img.height },
                                reduced: { width: Math.round(img.width / 1.5), height: Math.round(img.height / 1.5) }
                            }
                        }
                    }
                    return camera;
                });

                window.conf.CAMERAS = newCameras;

                setControlledData({
                    ...controlledData,
                    width: img.width,
                    height: img.height,
                });
            })
            .catch(error => console.error(error));
        }
    }, [controlledData.camera]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const current = refSider.current;
        if (!current) return;

        const observer = new ResizeObserver(() => {
            const newWidth = current.clientWidth - accordionOffset; // current sider width without padding of accordion
            setSiderWidth((prevWidth) => (prevWidth !== newWidth ? newWidth : prevWidth)); // only update if the new width is different from the current state
        });

        observer.observe(current);
        return () => observer.disconnect();
    }, []);

    return (
        <Box sx={{ width: "100%" }} ref={refSider}>

            {/* total time lapse */}

            <Accordion 
                expanded={expanded === 'panel1'}
                isPortrait={isPortrait}
                onChange={onChangeExpanded('panel1')}
            >
                <AccordionSummary>
                    {t("timelapse.total")}
                </AccordionSummary>
                <AccordionDetails>

                    {moreThanOneCam && (
                        <TextField
                            select
                            label={t("events.camera")}
                            value={controlledData.camera.id}
                            onChange={onChangeSelectedCamera}
                            fullWidth
                            sx={{ pb: 2 }}
                            disabled={disabledForm}
                        >
                            {cameras.map((camera) => (
                                <MenuItem key={camera.id} value={camera.id}>
                                    {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px",
                            mt: moreThanOneCam && 2
                        }}
                    >
                        <Box sx={{ position: "relative", width: "100%" }}>
                            <LoadingButton
                                sx={{ width: "100%", transition: "all 0.3s ease-in-out" }}
                                variant="contained"
                                size="large"
                                startIcon={<Download />}
                                onClick={handleDownloadTotal}
                                disabled={disabledButton}
                                loading={isDownloadingTotal}
                                loadingIndicator={
                                    <p style={{
                                        width: "180px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}>
                                        {isDownloadingTotal ? t("actions.downloading") : t("timelapse.loading")}...
                                    </p>
                                }
                            >
                                {t("actions.download")}
                            </LoadingButton>

                            {isDownloadingTotal && (
                                <Progress value={calculateProgressTotal()} />
                            )}

                        </Box>

                        {isDownloadingTotal && (
                            <Button
                                sx={{ flexShrink: 0 }}
                                variant="outlined"
                                size="large"
                                onClick={handleCancelTotal}
                            >
                                <Close />
                            </Button>
                        )}

                    </Box>

                </AccordionDetails>
            </Accordion>

            {/* custom time lapse */}

            <Accordion 
                expanded={expanded === 'panel2'}
                onChange={onChangeExpanded('panel2')}
            >
                <AccordionSummary >
                    {t("timelapse.custom")}
                </AccordionSummary>
                <AccordionDetails>

                    {moreThanOneCam && (
                        <TextField
                            select
                            label={t("events.camera")}
                            value={controlledData.camera.id}
                            onChange={onChangeSelectedCamera}
                            fullWidth
                            sx={{ pb: 2 }}
                            disabled={disabledForm}
                        >
                            {cameras.map((camera) => (
                                <MenuItem key={camera.id} value={camera.id}>
                                    {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}

                    <DateRangePicker
                        isDisabled={controlledData.camera == null || disabledForm}
                        isLoading={isCameraLoading}
                        value={[controlledData.timespanStart, controlledData.timespanEnd]}
                        onChange={(dateRange) => {
                            setControlledData({
                                ...controlledData,
                                timespanStart: dateRange[0],
                                timespanEnd: dateRange[1],
                            });
                            setAlertMessage(null);
                        }}
                        error={[errorMessages.timespanStart, errorMessages.timespanEnd]}
                        onError={(errors) => {
                            setErrorMessages({
                                ...errorMessages,
                                timespanStart: errors[0],
                                timespanEnd: errors[1],
                            });
                        }}
                        siderWidth={siderWidth}
                    />

                    <Box sx={{
                        p: 2,
                        marginTop: 0,
                        borderRadius: "4px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        userSelect: "none"
                    }}>

                        <Box sx={{ width: "100%" }}>
                            <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={controlledData.useRecommendedLength}
                                        onChange={(event) => {
                                            setControlledData({
                                                ...controlledData,
                                                useRecommendedLength: event.target.checked,
                                            });
                                            setAlertMessage(null);
                                        }}
                                        disabled={disabledForm}
                                    />
                                }
                                label={t("timelapse.use_recommended_length")}
                            />
                            </FormGroup>
                        </Box>

                        <Box sx={{ 
                            width: "100%",
                            marginTop: 1,
                            position: "relative"
                        }}>

                            <Typography sx={{ color: disabledForm || controlledData.useRecommendedLength ? "#bdbdbd" : "rgba(0, 0, 0, 0.87)" }}>
                                {t("timelapse.maximum_length")}
                                {!controlledData.useRecommendedLength && `: ${controlledData.maxLength} ${t("timelapse.seconds")}`}
                            </Typography>

                            {errorMessages.maxLength && (
                                <Typography variant="caption" color="red">
                                    {errorMessages.maxLength}
                                </Typography>
                            )}

                            <Box style={{
                                boxSizing: "content-box",
                                padding: "0 10px 10px",
                            }}>

                                <PrimarySliderCustom
                                    aria-label={t("timelapse.maximum_length")}
                                    value={controlledData.maxLength}
                                    getAriaValueText={(value) => `${value}%`}
                                    step={10}
                                    min={10}
                                    max={120}
                                    disabled={disabledForm || controlledData.useRecommendedLength}
                                    valueLabelDisplay="auto"
                                    marks={[
                                        {
                                            value: 10,
                                            label: "10",
                                        },
                                        {
                                            value: 120,
                                            label: "120",
                                        }
                                    ]}
                                    onChange={handleChangeMaxLength}
                                />

                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: 0,
                                    bottom: 10
                                }}>

                                    <Typography 
                                        sx={{ 
                                            fontSize: "0.875rem",
                                            color:
                                                disabledForm || controlledData.useRecommendedLength || controlledData.maxLength !== 10
                                                    ? "#bdbdbd"
                                                    : "rgba(0, 0, 0, 0.87)",
                                        }}
                                    >
                                        10
                                    </Typography>

                                    <Typography 
                                        sx={{ 
                                            fontSize: "0.875rem",
                                            color:
                                                disabledForm || controlledData.useRecommendedLength || controlledData.maxLength !== 120
                                                    ? "#bdbdbd"
                                                    : "rgba(0, 0, 0, 0.87)",
                                        }}
                                    >
                                        120
                                    </Typography>

                                </Box>
                            </Box>
                        </Box>

                        <Divider />

                        <Box sx={{
                            width: "100%",
                            marginTop: 2,
                            position: "relative",
                        }}>
                            <Typography sx={{ color: disabledForm ? "#bdbdbd" : "rgba(0, 0, 0, 0.87)"}}>
                                {t("timelapse.quality")}: {controlledData.width}x
                                {controlledData.height}
                            </Typography>
                            <Box style={{
                                boxSizing: "content-box",
                                padding: "0 10px",
                            }}>
                                <PrimarySliderCustom
                                    aria-label={t("timelapse.quality")}
                                    value={controlledData.width === quality.reduced.width ? 1 : 2}
                                    getAriaValueText={() => `${controlledData.width}x${controlledData.height}`}
                                    valueLabelFormat={() => `${controlledData.width}x${controlledData.height}`}
                                    disabled={disabledForm}
                                    step={1}
                                    min={1}
                                    max={2}
                                    valueLabelDisplay="auto"
                                    marks={[
                                    {
                                        value: 1,
                                        label: `${quality.reduced.width}x${quality.reduced.height}`,
                                    },
                                    {
                                        value: 2,
                                        label: `${quality.original.width}x${quality.original.height}`,
                                    },
                                    ]}
                                    onChange={(event, value) => {
                                        setControlledData({
                                            ...controlledData,
                                            width:
                                            value === 1
                                                ? quality.reduced.width
                                                : quality.original.width,
                                            height:
                                            value === 1
                                                ? quality.reduced.height
                                                : quality.original.height,
                                        });
                                        setAlertMessage(null);
                                    }}
                                />
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: 0,
                                    bottom: 0,
                                }}>
                                    <Typography sx={{ 
                                        fontSize: "0.875rem",
                                        color: disabledForm || controlledData.width !== quality.reduced.width
                                            ? "#bdbdbd"
                                            : "rgba(0, 0, 0, 0.87)"
                                    }}>
                                        {t("timelapse.reduced")}
                                    </Typography>
                                    <Typography sx={{ 
                                        fontSize: "0.875rem",
                                        color: disabledForm || controlledData.width !== quality.original.width
                                            ? "#bdbdbd"
                                            : "rgba(0, 0, 0, 0.87)"
                                    }}>
                                        {t("timelapse.original")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {alertMessage?.message && (
                        <Box sx={{
                            width: "100%",
                            mt: 2,
                        }}>
                            <Alert
                                variant="filled"
                                severity={alertMessage?.type}
                                sx={{ alignItems: "center", userSelect: "none" }}
                            >
                                {alertMessage?.message}
                            </Alert>
                        </Box>
                    )}

                    <Box sx={{ mt: "auto" }} />

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px",
                            mt: 2
                        }}
                        ref={refDownloadButton}
                    >
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                            }}
                        >
                            <LoadingButton
                                sx={{ width: "100%", transition: "all 0.3s ease-in-out" }}
                                variant="contained"
                                size="large"
                                startIcon={<Queue />}
                                onClick={handleAddTimelapseJob}
                                disabled={disabledButton}
                                loading={loadingJob || jobProgress || isDownloading}
                                loadingIndicator={
                                    delay.total === 0 && !isDownloading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <p style={{
                                            width: "180px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>
                                            {isDownloading ? t("actions.downloading") : t("timelapse.loading")}...
                                        </p>
                                    )
                                }
                            >
                                {t("timelapse.action")}
                            </LoadingButton>

                            {(((loadingJob || jobProgress) && delay.total > 0) || isDownloading) &&
                                <Progress value={calculateProgress()} />
                            }

                        </Box>

                        {jobProgress && !isCanceling && (
                            <Button
                                sx={{ flexShrink: 0 }}
                                variant="outlined"
                                size="large"
                                disabled={loadingCancel || isCanceling}
                                onClick={handleCancel}
                            >
                                <Close />
                            </Button>
                        )}

                    </Box>

                </AccordionDetails>
            </Accordion>

        </Box>
    )
}
import React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography, Fade, TableContainer, Skeleton } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export default function Events(props) {

    const { t } = useTranslation()
    const { events, footerOpen, footerHeightCalendar, preview, loading, onOpenTagModal } = props
    const { isPortrait } = useMobileOrientation()

    const camCount = window.conf.CAMERAS.length

    function selectEvent(row) {
        onOpenTagModal(row)
    }

    if (isMobile && isPortrait) {

        // mobile footer closed (preview opened)
        if (preview) {
            return (
                <Fade in={!footerOpen}>
                    <Table 
                        size="small" 
                        sx={{ 
                            borderTopWidth: 1, 
                            borderTopColor: "rgb(224, 224, 224)", 
                            borderTopStyle: 'solid',
                            tableLayout: "fixed"
                        }}
                    >
                        <TableBody>
                            {events?.map(row => (
                                <TableRow
                                    key={row.id}
                                    hover
                                    onClick={() => selectEvent(row)}
                                    sx={{ 
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        userSelect: "none",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {camCount > 1 && 
                                        <TableCell 
                                            sx={{ 
                                                py: "4px",
                                                pr: 0,
                                                pl: "12px"
                                            }}
                                        >
                                            <Typography 
                                                noWrap 
                                                className='text'
                                            > 
                                                {row.camera.name !== "" ? row.camera.name : `${t("events.camera")} ${row.camera.id + 1}`} 
                                            </Typography>
                                        </TableCell>
                                    }

                                    <TableCell 
                                        sx={{ 
                                            py: "4px", 
                                            pl: camCount > 1 ? 0 : "12px",
                                            pr: "12px",
                                        }}
                                        align={camCount > 1 ? "right" : "left"}
                                        colSpan={camCount > 1 ? 1 : 2}
                                    >
                                        <Typography 
                                            noWrap 
                                            className='text'
                                        > 
                                            {row.description} 
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Fade>
            )

        // mobile footer opened
        } else {
            return (
                <TableContainer 
                    sx={{ height: footerHeightCalendar - 189 }}
                >
                    <Table 
                        size="small" 
                        sx={{ tableLayout: "fixed" }}
                    >
                        <TableBody>
                            {events?.length
                            ? events.map(row => (
                                <TableRow
                                    key={row.id}
                                    hover
                                    onClick={() => selectEvent(row)}
                                    sx={{ 
                                        '&:last-child td, &:last-child th': { border: 0, pb: 0 },
                                        userSelect: "none",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {camCount > 1 && <TableCell 
                                        sx={{ 
                                            pr: 0,
                                            pl: "2px"
                                        }}
                                    >
                                        <Typography 
                                            noWrap 
                                            className='text'
                                        > 
                                            {row.camera.name !== "" ? row.camera.name : `${t("events.camera")} ${row.camera.id + 1}`} 
                                        </Typography>
                                    </TableCell>}
                                    <TableCell 
                                        sx={{ 
                                            pr: "4px",
                                            pl: 0
                                        }}
                                        align={camCount > 1 ? "right" : "left"}
                                        colSpan={camCount > 1 ? 1 : 2}
                                    >
                                        <Typography 
                                            noWrap 
                                            className='text'
                                        > 
                                            {row.description} 
                                        </Typography>                                         
                                    </TableCell>
                                </TableRow>
                            ))
                            : <Typography
                                noWrap 
                                className='text'
                                sx={{ fontStyle: "italic", userSelect: "none" }}
                                align='center'
                                component="tr"
                            >
                               <td>{t("msg.no_events")}</td>
                            </Typography>}
                        </TableBody>
                    </Table>
                </TableContainer>
            )            
        }
        
    // desktop sider opened
    } else {
        return (
            <TableContainer 
                sx={{
                    minHeight: 33, // minHeight: events?.length ? (3 * 33) : 22
                    height: "calc(100vh - 648px)",
                    overflowY: loading ? "hidden" : "scroll",
                    flexGrow: 1,
                }}
                id="scrollableDiv"
            >
                <Table 
                    size="small" 
                    sx={{ tableLayout: "fixed", flexGrow: 1, }}
                >
                    <TableBody>
                        {loading 
                        ? <>
                            <TableRow><TableCell><Skeleton /></TableCell></TableRow>
                            <TableRow><TableCell><Skeleton /></TableCell></TableRow>
                            <TableRow><TableCell><Skeleton /></TableCell></TableRow>
                            <TableRow><TableCell><Skeleton /></TableCell></TableRow>
                            <TableRow><TableCell><Skeleton /></TableCell></TableRow>
                            <TableRow><TableCell><Skeleton /></TableCell></TableRow>
                        </>
                        : events?.length
                            ? events.map(row => 
                                <TableRow
                                    key={row.id}
                                    hover
                                    onClick={() => selectEvent(row)}
                                    sx={{ 
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        userSelect: "none",
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {camCount > 1 && <TableCell 
                                        sx={{ 
                                            pr: 0,
                                            pl: "2px" 
                                        }}
                                    >
                                        <Typography 
                                            noWrap 
                                            className='text'
                                        > 
                                            {row.camera.name !== "" ? row.camera.name : `${t("events.camera")} ${row.camera.id + 1}`}
                                        </Typography>
                                    </TableCell>}
                                    <TableCell 
                                        sx={{ 
                                            pr: "4px",
                                            pl: 0
                                        }}
                                        align={camCount > 1 ? "right" : "left"}
                                        colSpan={camCount > 1 ? 1 : 2}
                                    >
                                        <Typography 
                                            noWrap 
                                            className='text'
                                        > 
                                            {row.description} 
                                        </Typography>                                         
                                    </TableCell>
                                </TableRow>
                            )
                            : <Typography 
                                noWrap 
                                className='text'
                                sx={{ fontStyle: "italic", userSelect: "none" }}
                                align='center'
                                component="tr"
                            >
                                <td>{t("msg.no_events")}</td>
                            </Typography>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}